import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/adler/adlermatchrankwise";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

function msToKmh(metersPerSecond) {
  // 1 m/s is equivalent to 3.6 km/h
  const kilometersPerHour = metersPerSecond * 3.6;
  // Round the result to two decimal places
  return parseFloat(kilometersPerHour.toFixed(2));
}

function mToKm(meters) {
  const kilometers = meters / 1000;
  // Round the result to two decimal places
  return parseFloat(kilometers.toFixed(2));
}

function sToMin(seconds) {
  const minutes = seconds / 60;
  // Round the result to two decimal places
  return parseFloat(minutes.toFixed(2));
}

const translations = {
  Group: "Gruppe",
};

// {
//   Goals,
//   Assists,
//   TotalGames,
//   YellowCards,
//   RedCards,
//   TotalGamesOverall,
//   GoalsOverall,
//   YellowCardsOverall,
//   RedCardsOverall,
//   SubOnOverall,
//   SubOffOverall,
//   AssistsOverall
// }
const RankingTypesToKeyPath = {
  distanceTravelled: "totalStatistics.skatingStatistics.distanceTravelled",
  fastestScater: "totalStatistics.skatingStatistics.topSpeed",
  skaterAcceleration: "totalStatistics.skatingStatistics.accelerations",
  timeOnIce: "totalStatistics.skatingStatistics.timeOnIce",
  shotSpeed: "speed",
};

const RankingTypesToColumn = {
  distanceTravelled: "Traveled Distance (m)",
  fastestScater: "Fastest Skater (m/s)",
  skaterAcceleration: "Skater acceleration",
  timeOnIce: "Time on Ice (s)",
  shotSpeed: "Shot Speed (m/s)",
};

const RankingTypesToHead = {
  distanceTravelled: (
    <div>
      Zurückgelegte <br />
      Distanz
    </div>
  ),
  fastestScater: (
    <div>
      Schnellster <br />
      Spieler
    </div>
  ),
  skaterAcceleration: (
    <div>
      Skater-
      <br />
      Beschl.
    </div>
  ),
  timeOnIce: (
    <div>
      Zeit <br />
      auf Eis
    </div>
  ),
  shotSpeed: (
    <div>
      Härtester <br />
      Schuss
    </div>
  ),
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`)
        .get(0)
        .addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const matchInfo = _.get(dynamic, "matchInfo");
  const matchRanks = _.get(dynamic, "matchRanks");

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const teamSide = getContentById("selectedSide", fields);
  const rankingType = getContentById("rankingType", fields);
  // distanceTravelled: "Traveled Distance (m)",
  //   fastestScater: "Fastest Skater (m/s)",
  //   skaterAcceleration: "Skater acceleration",
  //   timeOnIce: "Time on Ice (s)",
  //   shotSpeed: "Shot Speed (m/s)",
  // const rankingType = 'timeOnIce';
  const selectedRanking = matchRanks?.[teamSide]?.[rankingType] || [];
  const players = matchRanks?.players || [];

  const targetTeamId = teamSide === "home" ? homeTeam?.id : awayTeam?.id;

  const backgroundImage = getMainBackground(format, rankingType);
  const backgroundStaticImage = getStaticBackground(format, rankingType);

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${
        animation ? "animation" : ""
      } side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <div className={`wc-content ${rankingType}`}>
          {format === "1200x1500" && (
            <div className={"static-bg"}>
              <Backgrounds bg1={backgroundStaticImage} />
            </div>
          )}
          <div className={"video-bg"}>
            <Backgrounds bg1={backgroundImage} />
          </div>
          <img className={"sponsors sponsor-del"} src={assets.penny_del} />
          <div className={`headline`}>
            <div className={"main-line"}>
              {RankingTypesToHead?.[rankingType]}
            </div>
            <div className={"ghost-line"}>
              {RankingTypesToHead?.[rankingType]}
            </div>
          </div>
          {selectedRanking?.length &&
            selectedRanking.slice(0, 3).map((entry, index) => {
              const player = _.find(players, {
                id: entry.player || entry.shooter,
              });

              let converted = _.get(
                entry,
                RankingTypesToKeyPath[rankingType],
                0,
              );
              let statType = "";

              if (
                rankingType === "fastestScater" ||
                rankingType === "shotSpeed"
              ) {
                converted = msToKmh(converted);
                statType = "KM/H";
              }

              if (rankingType === "distanceTravelled") {
                // converted = mToKm(converted)
                converted = _.round(converted);
                statType = "M";
              }

              if (rankingType === "timeOnIce") {
                converted = sToMin(converted);
                statType = "M";
              }

              if (rankingType === "skaterAcceleration") {
                statType = "G";
              }

              return (
                <React.Fragment key={"player-" + index}>
                  <div className={"player-info info-" + index}>
                    <div className={"player-name"}>{player?.lastName}</div>
                    <div className={"stat-value"}>{converted ?? 0}</div>
                    <div className={"stat-type"}>{statType}</div>
                  </div>
                  <div className={"player player-" + index}>
                    <div className={"player-image"}>
                      <img
                        src={parseMediaUrl(
                          dynamic?.assets?.[`p_${player.id}`]?.url,
                        )}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          )
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, rankingType) => {
  if (format === "1920x1080") {
    if (rankingType === "shotSpeed") {
      return _.get(assets, `shot_speed_1920`);
    }

    if (rankingType === "fastestScater") {
      return _.get(assets, `fastest_scater_1920`);
    }

    if (rankingType === "distanceTravelled") {
      return _.get(assets, `distance_1920`);
    }

    return _.get(assets, `shot_speed_1920`);
  }

  if (format === "1200x1500") {
    if (rankingType === "shotSpeed") {
      return _.get(assets, `shot_speed_1200`);
    }

    if (rankingType === "fastestScater") {
      return _.get(assets, `fastest_scater_1200`);
    }

    if (rankingType === "distanceTravelled") {
      return _.get(assets, `distance_1200`);
    }

    return _.get(assets, `shot_speed_1200`);
  }
};

const getStaticBackground = (format, rankingType) => {
  if (rankingType === "distanceTravelled") {
    return _.get(assets, `red_bg`);
  }

  return _.get(assets, `blue_bg`);
};

export default Index;
