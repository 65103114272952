export default {
  id: "3000",
  name: "Timeline test",
  format: "1920x1080",
  options: {
    mode: "auto",
  },
  templates: [
    {
      templateId: "0002",
      template: "playerstats",
    },
    {
      templateId: "0003",
      template: "teamfacts",
    },
    {
      templateId: "0004",
      template: "playercompare",
    },
    {
      templateId: "0005",
      template: "teamcompare",
    },
  ],
  total: 4,
  usedTemplates: [
    "playerfacts",
    "playerstats",
    "teamfacts",
    "teamcompare",
    "playercompare",
  ],
};
