import _ from "lodash";
import extToType from "../functions/extToType";

export default async (assets) => {
  const promises = _.map(assets, (src, key) => {
    return new Promise((resolve, reject) => {
      const mediaType = extToType(src);
      // console.log("src", src);

      if (mediaType === "image") {
        const img = new Image();
        // img.src = parseMediaUrl(src);
        img.src = src;
        // console.log("###src", src);

        img.onload = resolve();
        img.onerror = function () {
          console.warn("Preload image failed:", src);
          reject(src);
        };
      } else if (mediaType === "video") {
        const video = document.createElement("video");
        video.src = src;

        video.addEventListener("canplaythrough", (event) => {
          resolve();
        });

        video.onerror = function () {
          console.warn("Preload video failed:", src);
          reject(src);
        };
      } else {
        resolve();
      }
    });
  });
  return promises;
};
