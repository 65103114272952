import { clearTimeouts, engine as anime } from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;

  const timeline = anime.timeline();
  const templateDuration =
    _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0;
  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  // .group-rows, .main-header, .qatar-logo, .group-label

  // $(`#${id} .qatar-logo, #${id} .main-headline, #${id} .pitch-container, #${id} .player`).css("opacity", 0);

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200,
  });

  anime({
    targets: [`#${id} .main-header`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 400,
  });

  anime({
    targets: [`#${id} .sub-header`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 600,
  });

  const timeout1 = setTimeout(() => {
    anime({
      targets: [$(`#${id} .group-rows`).get()],
      duration: 1200,
      easing: "easeOutQuint",
      translateY: [-2000, 0],
      opacity: [1, 1],
      delay: anime.stagger(300),
    });

    anime({
      targets: [$(`#${id} .left-lines`).get()],
      duration: 1200,
      easing: "easeOutQuint",
      opacity: [0, 1],
      delay: 2000,
    });

    anime({
      targets: [$(`#${id} .right-lines`).get()],
      duration: 1200,
      easing: "easeOutQuint",
      opacity: [0, 1],
      delay: 2000,
    });

    anime({
      targets: [$(`#${id} .main-label-final > div`).get()],
      duration: 1200,
      easing: "easeOutQuint",
      opacity: [0, 1],
      delay: 1700,
    });

    anime({
      targets: [$(`#${id} .main-label-final > img`).get()],
      duration: 1200,
      easing: "easeOutQuint",
      translateY: [-2000, 0],
      opacity: [0, 1],
      delay: 1700,
    });

    anime({
      targets: [$(`#${id} .main-label-3rd`).get()],
      duration: 1200,
      easing: "easeOutQuint",
      opacity: [0, 1],
      delay: 1900,
    });
  }, 400);

  timeouts.push(timeout1);

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
