import _ from "lodash";

export const parseGoal = (goal, reverse = false, onlyBalance = false) => {
  const powerPlay =
    !goal?.balance || goal?.balance === "EQ" ? "" : `/${goal?.balance}`;

  if (onlyBalance) {
    return powerPlay?.replace("/", "");
  }

  if (reverse) {
    return `[${_.get(goal, "scoreboardTime[0]")}${powerPlay}] ${goal.surname}`;
  } else {
    return `${goal?.surname} [${_.get(goal, "scoreboardTime[0]")}${powerPlay}]`;
  }
};
