import { clearTimeouts, engine as anime } from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;

  const timeline = anime.timeline();

  $(`#${id} .player, #${id} .player-shadow`).css("opacity", 0);
  $(`#${id} .player-name > div`).css("opacity", 0);
  $(`#${id} .score`).css("opacity", 0);
  $(
    `#${id} .teams-block .home, #${id} .teams-block .away, #${id} .goal-description-box`,
  ).css("opacity", 0);

  const timeout1 = setTimeout(() => {
    anime({
      targets: [`#${id} .player`],
      duration: 1200,
      easing: "easeOutQuint",
      translateY: [2000, 0],
      scale: [0, 1],
      opacity: [1, 1],
      delay: 200,
    });

    anime({
      targets: $(`#${id} .player-name > div`).toArray().reverse(),
      duration: 1000,
      easing: "easeOutQuint",
      scale: [0, 1],
      // rotateZ: [-90, -90],
      opacity: [1, 1],
      delay: anime.stagger(200, { start: 400 }),
    });

    anime({
      targets: [`#${id} .goal-description-box`],
      duration: 1000,
      easing: "easeOutQuint",
      scale: [0, 1],
      opacity: [1, 1],
      delay: 500,
    });

    anime({
      targets: [`#${id} .teams-block .home`],
      duration: 1000,
      easing: "easeOutQuint",
      translateX: [-800, 0],
      opacity: [1, 1],
      delay: 700,
    });

    anime({
      targets: [`#${id} .teams-block .away`],
      duration: 1000,
      easing: "easeOutQuint",
      translateX: [800, 0],
      opacity: [1, 1],
      delay: 700,
    });

    anime({
      targets: [`#${id} .score`],
      duration: 800,
      easing: "easeOutQuint",
      scale: [0, 1],
      opacity: [1, 1],
      delay: 800,
    });
  }, 1200);

  timeouts.push(timeout1);

  return {
    timeline: timeline,
    timeouts: timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
