import { clearTimeouts, engine as anime } from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;

  const timeline = anime.timeline();

  $(`#${id} .team-logo-container`).css("opacity", 0);
  $(`#${id} .score-home, #${id} .score-away`).css("opacity", 0);
  $(`#${id} .periods-container > div`).css("opacity", 0);
  $(`#${id} .home-scorers > div, #${id} .away-scorers > div`).css("opacity", 0);
  $(`#${id} .periods-container > div`).css("opacity", 0);

  anime({
    targets: [`#${id} .custom-background`],
    duration: 14000,
    easing: "linear",
    scale: [1, 1.35],
    delay: 0,
  });

  anime({
    targets: [`#${id} .team-logo-container.home`],
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    translateX: [-800, 0],
    delay: 1100,
  });

  anime({
    targets: [`#${id} .team-logo-container.away`],
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    translateX: [800, 0],
    delay: 1100,
  });

  anime({
    targets: [$(`#${id} .home-scorers > div`).toArray().reverse()],
    duration: 700,
    easing: "easeInQuint",
    opacity: [0, 1],
    // rotateZ: ["22deg", 0],
    delay: anime.stagger(150, { start: 1000 }),
  });

  anime({
    targets: [$(`#${id} .away-scorers > div`).toArray().reverse()],
    duration: 700,
    easing: "easeInQuint",
    opacity: [0, 1],
    // rotateZ: ["22deg", 0],
    delay: anime.stagger(150, { start: 1000 }),
  });

  anime({
    targets: [`#${id} .periods-container > div`],
    duration: 600,
    easing: "easeOutQuint",
    opacity: [0, 1],
    // rotateZ: ["22deg", 0],
    delay: anime.stagger(100, { start: 1100 }),
  });

  anime({
    targets: [`#${id} .score-home`],
    duration: 600,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    // rotateZ: ["22deg", 0],
    delay: 900,
  });

  anime({
    targets: [`#${id} .score-away`],
    duration: 600,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    // rotateZ: ["22deg", 0],
    delay: 900,
  });

  // timeouts.push(timeout1);
  // timeouts.push(timeout2);

  return {
    timeline: timeline,
    timeouts: timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
