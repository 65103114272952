import { clearTimeouts, engine as anime } from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;

  const timeline = anime.timeline();
  const templateDuration =
    _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0;

  $(`#${id} .main-title, #${id} .sub-title`).css("opacity", 0);
  $(
    `#${id} .live-matchdays-container > div, #${id} .live-matchdays-positioner`,
  ).css("opacity", 0);
  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200,
  });

  anime({
    targets: [`#${id} .competition-positioner`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200,
  });

  anime({
    targets: [`#${id} .main-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .second-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 500,
  });

  const timeout1 = setTimeout(() => {
    anime({
      targets: [`#${id} .live-matchdays-positioner`],
      duration: 1000,
      easing: "easeOutQuint",
      // height: [0, ($(`#${id} .live-matchdays-container`).height()) + "px"],
      opacity: [0, 1],
    });
    anime({
      targets: [`#${id} .live-matchdays-container > div`],
      duration: 1300,
      easing: "easeOutQuint",
      // height: [0, ($(`#${id} .live-matchdays-container`).height()) + "px"],
      translateX: [-2000, 0],
      // rotateZ: [22, 22],
      opacity: [1, 1],
      delay: anime.stagger(150),
    });
  }, 800);

  const timeout2 = setTimeout(() => {
    const $targetContainer = $(`#${id} .live-matchdays-container`);
    const $targetPositioner = $(
      `#${id} .live-matchdays-positioner`,
    ).outerHeight();
    const visibleHeight = $targetPositioner;
    const contentHeight = $targetContainer.outerHeight();
    const heightOutOfView = contentHeight - visibleHeight;

    if (heightOutOfView > 0) {
      anime({
        targets: [`#${id} .live-matchdays-container`],
        duration: 4000,
        easing: "easeInOutQuad",
        top: [0, -heightOutOfView - 1],
        // rotateZ: [22, 22],
        opacity: [1, 1],
        delay: 0,
      });
    }
  }, 7000);

  timeouts.push(timeout1);
  timeouts.push(timeout2);

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
