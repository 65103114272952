import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/matchdayoverview";

import assets from "./assets";
import { format as dateFormat } from "date-fns";
import { de, enUS } from "date-fns/locale";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../language/de/countries.json";
import { useTranslation } from "react-i18next";
import { toIsoDateString } from "../../../../shared/utils/functions";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

// const stageToStringClass = {
//   "Semi-finals": "stageSemiFinals",
//   "3rd Place Final": "stage",
//   "8th Finals": "1/8",
//   "Final": "1/8",
//   "Quarter-finals": "1/8",
// };

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t, i18n } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const lng = i18n?.language;
  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const matchInfo = _.get(dynamic, "matchInfo");
  const matchdays = _.get(dynamic, "matchdays");
  const series = _.get(dynamic, "series");
  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const teamSide = getContentById("selectedSide", fields);

  const targetTeamId = teamSide === "home" ? homeTeam?.id : awayTeam?.id;

  // const standing = _.get(dynamic, "standing");
  //
  // const findGroup = _.find(standing, (group) => {
  //   return _.find(group?.ranking, {contestantId: targetTeamId});
  // });

  const groupByStage = _.groupBy(matchdays, function (m) {
    return m?.matchInfo?.stage?.name;
  });

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${
        animation ? "animation" : ""
      } side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        {/*<Backgrounds bg1={backgroundImage} loop={true}/>*/}
        <WcBgCircle />

        <div className={"wc-content"}>
          <div className={"wc-bg-content"}></div>

          <img className={"qatar-logo"} src={assets.qatar_logo} />

          <div className={"main-header green-glow"}>Spielplan</div>
          <div className={"sub-header"}>
            {t("Women's World Cup", { keyPrefix: "templates" })}
          </div>

          <div className={"main-label-final"}>
            <div>
              <span className={"green-glow"}>F</span>inale
            </div>
            <img className={"world-cup-trophy"} src={assets.trophy} />
          </div>
          <div className={"main-label-3rd"}>
            <div>
              Spiel um <span className={"green-glow"}>Platz 3</span>
            </div>
          </div>

          <div className={"left-lines"}>
            <div className={"final-line final-line-1"}></div>
            <div className={"final-line final-line-2"}></div>

            <div className={`line line1`}></div>
            <div className={`line line2`}></div>
            <div className={`line line3`}></div>
            <div className={`line line4`}></div>

            <div className={`line line5`}></div>
            <div className={`line line6`}></div>
          </div>

          <div className={"right-lines"}>
            <div className={`line line1`}></div>
            <div className={`line line2`}></div>
            <div className={`line line3`}></div>
            <div className={`line line4`}></div>

            <div className={`line line5`}></div>
            <div className={`line line6`}></div>
          </div>

          <div className={"group-table"}>
            <div
              className={`group-rows stage-${"8th Finals".replace(/\s/g, "")}`}
            >
              {_.map(groupByStage?.["8th Finals"], (matchday, index) => {
                const matchDate = new Date(
                  toIsoDateString(
                    matchday?.matchInfo?.date,
                    matchday?.matchInfo?.time,
                  ),
                );
                const time = dateFormat(matchDate, "HH:mm", { locale: de });
                const weekday = dateFormat(matchDate, "eeeeee", { locale: de });

                const rowHomeName = matchday?.matchInfo?.contestant?.[0]?.name;
                const rowAwayName = matchday?.matchInfo?.contestant?.[1]?.name;

                const teamHomeAlpha2 = getOptaCountryCode(rowHomeName);
                const teamAwayAlpha2 = getOptaCountryCode(rowAwayName);

                const matchStatus = _.toLower(
                  matchday?.liveData?.matchDetails?.matchStatus,
                );
                const hasScore =
                  matchStatus === "played" || matchStatus === "playing"
                    ? true
                    : false;
                const scores = matchday?.liveData?.matchDetails?.scores?.total;

                const stageName = matchday?.matchInfo?.stage?.name;

                const getDescription = (matchday) => {
                  switch (matchday?.matchInfo?.id) {
                    case "e5o0kr3gpx9rknzr49yf52b6c": {
                      //10.07
                      return "Zweiter Gruppe D";
                    }
                    case "e5a4zqab1x2lqlgfy61ya77ys": {
                      //Jamaica
                      return "Erster Gruppe D";
                    }
                    case "e4i8aj8q2sic69hrbf6011fkk": {
                      //10.07
                      return "Erster Gruppe C";
                    }
                    case "e4wb8wdvkjfi50eyyweskptlg": {
                      //Mexico
                      return "Zweiter Gruppe C";
                    }
                  }
                };

                return (
                  <div
                    key={matchday?.id}
                    className={`group-row group-row-index-${index}`}
                  >
                    {/*<div>M:{matchday?.matchInfo?.id}</div>*/}
                    {/*<div>W:{matchday?.matchInfo?.nextMatchWinnerId}</div>*/}
                    <div className={"match-block"}>
                      <div className={"match-time"}>
                        <div className={"row-day"}>
                          {dateFormat(
                            matchDate,
                            lng === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy",
                            { locale: lng === "de" ? de : enUS },
                          )}
                        </div>
                        <div className={"row-time green-glow"}>
                          {hasScore ? (
                            <>
                              {time}
                              <div className={"timezone-time"}>
                                {t("TIME", { keyPrefix: "templates" })}
                              </div>
                            </>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className={"team-name home"}>
                        {t(rowHomeName, { ns: "countries" }) ||
                          getDescription(matchday)?.home ||
                          "-"}
                      </div>
                      <div className={"team-home"}>
                        {teamHomeAlpha2 ? (
                          <img
                            src={`/images/countries/svg/${teamHomeAlpha2}.svg`}
                          />
                        ) : (
                          "?"
                        )}
                      </div>
                      <div className={"row-score"}>
                        {hasScore ? (
                          <>
                            <div
                              className={"score-home"}
                            >{`${scores?.home || 0}`}</div>
                            <div
                              className={"score-away"}
                            >{`${scores?.away || 0}`}</div>
                          </>
                        ) : null}
                      </div>
                      <div className={"team-away"}>
                        {teamAwayAlpha2 ? (
                          <img
                            src={`/images/countries/svg/${teamAwayAlpha2}.svg`}
                          />
                        ) : (
                          "?"
                        )}
                      </div>
                      <div className={"team-name away"}>
                        {t(rowAwayName, { ns: "countries" }) ||
                          getDescription(matchday)?.away ||
                          "-"}
                      </div>
                      {matchStatus === "playing" ? (
                        <div className={"row-score-status blink-me"}>LIVE</div>
                      ) : (
                        !hasScore && (
                          <div className={"row-score-weekday"}>
                            <div className={"row-day"}>{weekday}.</div>
                            <div className={"row-time green-glow"}>
                              {time}
                              <div className={"timezone-time"}>
                                {t("TIME", { keyPrefix: "templates" })}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={`group-rows stage-${"Quarter-finals".replace(/\s/g, "")}`}
            >
              {_.map(groupByStage?.["Quarter-finals"], (matchday, index) => {
                const matchDate = new Date(
                  toIsoDateString(
                    matchday?.matchInfo?.date,
                    matchday?.matchInfo?.time,
                  ),
                );

                const time = matchday?.matchInfo?.time
                  ? dateFormat(matchDate, "HH:mm", { locale: de })
                  : "-";
                const weekday = dateFormat(matchDate, "eeeeee", { locale: de });

                const rowHomeName = matchday?.matchInfo?.contestant?.[0]?.name;
                const rowAwayName = matchday?.matchInfo?.contestant?.[1]?.name;

                const teamHomeAlpha2 = getOptaCountryCode(rowHomeName);
                const teamAwayAlpha2 = getOptaCountryCode(rowAwayName);

                const matchStatus = _.toLower(
                  matchday?.liveData?.matchDetails?.matchStatus,
                );
                const hasScore =
                  matchStatus === "played" || matchStatus === "playing"
                    ? true
                    : false;
                const scores = matchday?.liveData?.matchDetails?.scores?.total;

                const getDescription = (matchday) => {
                  switch (matchday?.matchInfo?.id) {
                    case "e5o0kr3gpx9rknzr49yf52b6c": {
                      //10.07
                      return "Zweiter Gruppe D";
                    }
                    case "e5a4zqab1x2lqlgfy61ya77ys": {
                      //Jamaica
                      return "Erster Gruppe D";
                    }
                    case "e4i8aj8q2sic69hrbf6011fkk": {
                      //10.07
                      return "Erster Gruppe C";
                    }
                    case "e4wb8wdvkjfi50eyyweskptlg": {
                      //Mexico
                      return "Zweiter Gruppe C";
                    }
                  }
                };

                const stageName = matchday?.matchInfo?.stage?.name;

                return (
                  <div
                    key={matchday?.id}
                    className={`group-row group-row-index-${index}`}
                  >
                    {/*<div>M:{matchday?.matchInfo?.id}</div>*/}
                    {/*<div>W:{matchday?.matchInfo?.nextMatchWinnerId}</div>*/}
                    <div className={"match-block"}>
                      <div className={"match-time"}>
                        <div className={"row-day"}>
                          {dateFormat(
                            matchDate,
                            lng === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy",
                            { locale: lng === "de" ? de : enUS },
                          )}
                        </div>
                        <div className={"row-time green-glow"}>
                          {hasScore ? (
                            <>
                              {time}
                              <div className={"timezone-time"}>
                                {t("TIME", { keyPrefix: "templates" })}
                              </div>
                            </>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className={"team-name home"}>
                        {t(rowHomeName, { ns: "countries" }) ||
                          getDescription(matchday)?.home ||
                          "-"}
                      </div>
                      <div className={"team-home"}>
                        {teamHomeAlpha2 ? (
                          <img
                            src={`/images/countries/svg/${teamHomeAlpha2}.svg`}
                          />
                        ) : (
                          "?"
                        )}
                      </div>
                      <div className={"row-score"}>
                        {hasScore ? (
                          <>
                            <div
                              className={"score-home"}
                            >{`${scores?.home || 0}`}</div>
                            <div
                              className={"score-away"}
                            >{`${scores?.away || 0}`}</div>
                          </>
                        ) : null}
                      </div>
                      <div className={"team-away"}>
                        {teamAwayAlpha2 ? (
                          <img
                            src={`/images/countries/svg/${teamAwayAlpha2}.svg`}
                          />
                        ) : (
                          "?"
                        )}
                      </div>
                      <div className={"team-name away"}>
                        {t(rowAwayName, { ns: "countries" }) ||
                          getDescription(matchday)?.away ||
                          "-"}
                      </div>
                      {matchStatus === "playing" ? (
                        <div className={"row-score-status blink-me"}>LIVE</div>
                      ) : (
                        !hasScore && (
                          <div className={"row-score-weekday"}>
                            <div className={"row-day"}>{weekday}.</div>
                            <div className={"row-time green-glow"}>
                              {time}
                              <div className={"timezone-time"}>
                                {t("TIME", { keyPrefix: "templates" })}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={`group-rows stage-${"Semi-finals".replace(/\s/g, "")}`}
            >
              {_.map(groupByStage?.["Semi-finals"], (matchday, index) => {
                const matchDate = new Date(
                  toIsoDateString(
                    matchday?.matchInfo?.date,
                    matchday?.matchInfo?.time,
                  ),
                );
                const time = matchday?.matchInfo?.time
                  ? dateFormat(matchDate, "HH:mm", { locale: de })
                  : "-";
                const weekday = dateFormat(matchDate, "eeeeee", { locale: de });

                const rowHomeName = matchday?.matchInfo?.contestant?.[0]?.name;
                const rowAwayName = matchday?.matchInfo?.contestant?.[1]?.name;

                const teamHomeAlpha2 = getOptaCountryCode(rowHomeName);
                const teamAwayAlpha2 = getOptaCountryCode(rowAwayName);

                const getDescription = (matchday) => {
                  switch (matchday?.matchInfo?.id) {
                    case "93vltc1s3lc05bneg163l9uz8": {
                      //10.07
                      return {
                        home: "Sieger VF 2",
                        away: "Sieger VF 3",
                      };
                    }
                    case "93gzqvd2bzadi5hsyc3lws3ro": {
                      //Jamaica
                      return {
                        home: "Sieger VF 1",
                        away: "Sieger VF 4",
                      };
                    }
                  }
                };

                const matchStatus = _.toLower(
                  matchday?.liveData?.matchDetails?.matchStatus,
                );
                const hasScore =
                  matchStatus === "played" || matchStatus === "playing"
                    ? true
                    : false;
                const scores = matchday?.liveData?.matchDetails?.scores?.total;

                const stageName = matchday?.matchInfo?.stage?.name;

                return (
                  <div
                    key={matchday?.id}
                    className={`group-row group-row-index-${index}`}
                  >
                    {/*<div>M:{matchday?.matchInfo?.id}</div>*/}
                    {/*<div>W:{matchday?.matchInfo?.nextMatchWinnerId}</div>*/}
                    <div className={"match-block"}>
                      <div className={"match-time"}>
                        <div className={"row-day"}>
                          {dateFormat(
                            matchDate,
                            lng === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy",
                            { locale: lng === "de" ? de : enUS },
                          )}
                        </div>
                        <div className={"row-time green-glow"}>
                          {hasScore ? (
                            <>
                              {time}
                              <div className={"timezone-time"}>
                                {t("TIME", { keyPrefix: "templates" })}
                              </div>
                            </>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className={"team-name home"}>
                        {t(rowHomeName, { ns: "countries" }) ||
                          getDescription(matchday)?.home ||
                          "-"}
                      </div>
                      <div className={"team-home"}>
                        {teamHomeAlpha2 ? (
                          <img
                            src={`/images/countries/svg/${teamHomeAlpha2}.svg`}
                          />
                        ) : (
                          "?"
                        )}
                      </div>
                      <div className={"row-score"}>
                        {hasScore ? (
                          <>
                            <div
                              className={"score-home"}
                            >{`${scores?.home || 0}`}</div>
                            <div
                              className={"score-away"}
                            >{`${scores?.away || 0}`}</div>
                          </>
                        ) : null}
                      </div>
                      <div className={"team-away"}>
                        {teamAwayAlpha2 ? (
                          <img
                            src={`/images/countries/svg/${teamAwayAlpha2}.svg`}
                          />
                        ) : (
                          "?"
                        )}
                      </div>
                      <div className={"team-name away"}>
                        {t(rowAwayName, { ns: "countries" }) ||
                          getDescription(matchday)?.away ||
                          "-"}
                      </div>
                      {matchStatus === "playing" ? (
                        <div className={"row-score-status blink-me"}>LIVE</div>
                      ) : (
                        !hasScore && (
                          <div className={"row-score-weekday"}>
                            <div className={"row-day"}>{weekday}.</div>
                            <div className={"row-time green-glow"}>
                              {time}
                              <div className={"timezone-time"}>
                                {t("TIME", { keyPrefix: "templates" })}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={`group-rows stage-${"Final".replace(/\s/g, "")}`}>
              {_.map(groupByStage?.["Final"], (matchday, index) => {
                const matchDate = new Date(
                  toIsoDateString(
                    matchday?.matchInfo?.date,
                    matchday?.matchInfo?.time,
                  ),
                );
                const time = matchday?.matchInfo?.time
                  ? dateFormat(matchDate, "HH:mm", { locale: de })
                  : "-";
                const weekday = dateFormat(matchDate, "eeeeee", { locale: de });

                const rowHomeName = matchday?.matchInfo?.contestant?.[0]?.name;
                const rowAwayName = matchday?.matchInfo?.contestant?.[1]?.name;

                const teamHomeAlpha2 = getOptaCountryCode(rowHomeName);
                const teamAwayAlpha2 = getOptaCountryCode(rowAwayName);

                const matchStatus = _.toLower(
                  matchday?.liveData?.matchDetails?.matchStatus,
                );
                const hasScore =
                  matchStatus === "played" || matchStatus === "playing"
                    ? true
                    : false;
                const scores = matchday?.liveData?.matchDetails?.scores?.total;

                const stageName = matchday?.matchInfo?.stage?.name;

                const getDescription = (matchday) => {
                  switch (matchday?.matchInfo?.id) {
                    case "elth5y4p5t2j2yrhhp0d0jxuc": {
                      //10.07
                      return {
                        home: "Sieger HF 1",
                        away: "Sieger HF 2",
                      };
                    }
                  }
                };

                return (
                  <div
                    key={matchday?.id}
                    className={`group-row group-row-index-${index}`}
                  >
                    {/*<div>M:{matchday?.matchInfo?.id}</div>*/}
                    {/*<div>W:{matchday?.matchInfo?.nextMatchWinnerId}</div>*/}
                    <div className={"match-block"}>
                      <div className={"match-time"}>
                        <div className={"row-day"}>
                          {dateFormat(
                            matchDate,
                            lng === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy",
                            { locale: lng === "de" ? de : enUS },
                          )}
                        </div>
                        <div className={"row-time green-glow"}>
                          {hasScore ? (
                            <>
                              {time}
                              <div className={"timezone-time"}>
                                {t("TIME", { keyPrefix: "templates" })}
                              </div>
                            </>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className={"team-name home"}>
                        {t(rowHomeName, { ns: "countries" }) ||
                          getDescription(matchday)?.home ||
                          "-"}
                      </div>
                      <div className={"team-home"}>
                        {teamHomeAlpha2 ? (
                          <img
                            src={`/images/countries/svg/${teamHomeAlpha2}.svg`}
                          />
                        ) : (
                          "?"
                        )}
                      </div>
                      <div className={"row-score"}>
                        {hasScore ? (
                          <>
                            <div
                              className={"score-home"}
                            >{`${scores?.home || 0}`}</div>
                            <div
                              className={"score-away"}
                            >{`${scores?.away || 0}`}</div>
                          </>
                        ) : null}
                      </div>
                      <div className={"team-away"}>
                        {teamAwayAlpha2 ? (
                          <img
                            src={`/images/countries/svg/${teamAwayAlpha2}.svg`}
                          />
                        ) : (
                          "?"
                        )}
                      </div>
                      <div className={"team-name away"}>
                        {t(rowAwayName, { ns: "countries" }) ||
                          getDescription(matchday)?.away ||
                          "-"}
                      </div>
                      {matchStatus === "playing" ? (
                        <div className={"row-score-status blink-me"}>LIVE</div>
                      ) : (
                        !hasScore && (
                          <div className={"row-score-weekday"}>
                            <div className={"row-day"}>{weekday}.</div>
                            <div className={"row-time green-glow"}>
                              {time}
                              <div className={"timezone-time"}>
                                {t("TIME", { keyPrefix: "templates" })}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={`group-rows stage-${"3rd Place Final".replace(/\s/g, "")}`}
            >
              {_.map(groupByStage?.["3rd Place Final"], (matchday, index) => {
                const matchDate = new Date(
                  toIsoDateString(
                    matchday?.matchInfo?.date,
                    matchday?.matchInfo?.time,
                  ),
                );
                const time = dateFormat(matchDate, "HH:mm", { locale: de });
                const weekday = dateFormat(matchDate, "eeeeee", { locale: de });

                const rowHomeName = matchday?.matchInfo?.contestant?.[0]?.name;
                const rowAwayName = matchday?.matchInfo?.contestant?.[1]?.name;

                const teamHomeAlpha2 = getOptaCountryCode(rowHomeName);
                const teamAwayAlpha2 = getOptaCountryCode(rowAwayName);

                const matchStatus = _.toLower(
                  matchday?.liveData?.matchDetails?.matchStatus,
                );
                const hasScore =
                  matchStatus === "played" || matchStatus === "playing"
                    ? true
                    : false;
                const scores = matchday?.liveData?.matchDetails?.scores?.total;

                const stageName = matchday?.matchInfo?.stage?.name;

                return (
                  <div
                    key={matchday?.id}
                    className={`group-row group-row-index-${index}`}
                  >
                    {/*<div>M:{matchday?.matchInfo?.id}</div>*/}
                    {/*<div>W:{matchday?.matchInfo?.nextMatchWinnerId}</div>*/}
                    <div className={"match-block"}>
                      <div className={"match-time"}>
                        <div className={"row-day"}>
                          {dateFormat(
                            matchDate,
                            lng === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy",
                            { locale: lng === "de" ? de : enUS },
                          )}
                        </div>
                        <div className={"row-time green-glow"}>
                          {hasScore ? (
                            <>
                              {time}
                              <div className={"timezone-time"}>
                                {t("TIME", { keyPrefix: "templates" })}
                              </div>
                            </>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className={"team-name home"}>
                        {t(rowHomeName, { ns: "countries" }) || "-"}
                      </div>
                      <div className={"team-home"}>
                        {teamHomeAlpha2 ? (
                          <img
                            src={`/images/countries/svg/${teamHomeAlpha2}.svg`}
                          />
                        ) : (
                          "?"
                        )}
                      </div>
                      <div className={"row-score"}>
                        {hasScore ? (
                          <>
                            <div
                              className={"score-home"}
                            >{`${scores?.home || 0}`}</div>
                            <div
                              className={"score-away"}
                            >{`${scores?.away || 0}`}</div>
                          </>
                        ) : null}
                      </div>
                      <div className={"team-away"}>
                        {teamAwayAlpha2 ? (
                          <img
                            src={`/images/countries/svg/${teamAwayAlpha2}.svg`}
                          />
                        ) : (
                          "?"
                        )}
                      </div>
                      <div className={"team-name away"}>
                        {t(rowAwayName, { ns: "countries" }) || "-"}
                      </div>
                      {matchStatus === "playing" ? (
                        <div className={"row-score-status blink-me"}>LIVE</div>
                      ) : (
                        !hasScore && (
                          <div className={"row-score-weekday"}>
                            <div className={"row-day"}>{weekday}.</div>
                            <div className={"row-time green-glow"}>
                              {time}
                              <div className={"timezone-time"}>
                                {t("TIME", { keyPrefix: "templates" })}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            {/*<div className={"group-content"}>*/}
            {/*  <div className={'table-head'}>*/}
            {/*    <div className={'col-pos col'}></div>*/}
            {/*    <div className={'col-team col'}></div>*/}
            {/*    <div className={'col-points col'}>SP.</div>*/}
            {/*    <div className={'col-points col'}>S</div>*/}
            {/*    <div className={'col-points col'}>U</div>*/}
            {/*    <div className={'col-points col'}>N</div>*/}
            {/*    <div className={'col-diff col'}>Tordiff.</div>*/}
            {/*    <div className={'col-pkt col'}>PKT</div>*/}
            {/*  </div>*/}
            {/*  {findGroup.ranking.map((entry, index)=> {*/}
            {/*    return <div key={'table-' + index} className={'table-row'}>*/}
            {/*      <div className={'col-pos col'}>{++index}.</div>*/}
            {/*      <div className={'col-team col'}><div className={'col-logo'}><img className="team-logo" src={`/images/countries/svg/${getOptaCountryCode(entry?.contestantName)}.svg`}/></div>{_.get(countries, entry.contestantName, entry.contestantName)}</div>*/}
            {/*      <div className={'col-points col'}>{entry.matchesPlayed}</div>*/}
            {/*      <div className={'col-points col'}>{entry.matchesWon}</div>*/}
            {/*      <div className={'col-points col'}>{entry.matchesDrawn}</div>*/}
            {/*      <div className={'col-points col'}>{entry.matchesLost}</div>*/}
            {/*      <div className={'col-diff col'}>{entry.goaldifference}</div>*/}
            {/*      <div className={'col-pkt col'}>{entry.points}</div>*/}
            {/*    </div>*/}
            {/*  })}*/}
            {/*</div>*/}
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
