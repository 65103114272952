import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import Player from './Player';
import $ from "jquery";
// import AnimationLibrary from 'FRONTENDNPM/src/frontend/src/templates/AnimationLibrary';
// import {
//     themePlayerFactsAnimation
// } from '../animations';

import getContentById from "../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../_partials/Backgrounds/Index";
import TemplateBlock from "../_partials/TemplateBlock/Index";

import { engine, fadeIn } from "../../../../animations/index";
import ImageOrVideo from "../../../shared/components/ImageOrVideo/Index";
// import Header from "./Header";
import Stats from "./Stats";
import animationScript from "../../../../animations/teamcompare";
import { animationCleanUp } from "../../../../animations/playerstats";
import parseMediaUrl from "../../../shared/functions/parseMediaUrl";

require("./style/style.scss");
require("./style/animation.scss");

const Index = (props) => {
  const refs = {
    teamHome: React.createRef(),
    teamAway: React.createRef(),
    playerHome: React.createRef(),
    playerAway: React.createRef(),
  };

  const { animation, data, images: Images, apiSetup, mode } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    // const {urlQuery} = this.props;
    // const $container = $('#' + this.props.id);
    // $container.find('.factsList > ul > li, .factsList > ol > li, .factsList > p ').wrapInner('<div class="li-wrapper"><div class="li-wrapper-content"> </div></div>');
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      prepareAnimation();
      animationReturn = animationScript({ refs, data, animation, mode, id });
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const teamHomeData = getContentById("teamHome", fields, "self");
  const teamHomeDataStyles = _.get(teamHomeData, "styles", {});

  const teamAwayData = getContentById("teamAway", fields, "self");
  const teamAwayDataStyles = _.get(teamAwayData, "styles", {});

  const playerHomeData = getContentById("playerHome", fields, "self");
  const playerHomeDataStyles = _.get(playerHomeData, "styles", {});

  const playerAwayData = getContentById("playerAway", fields, "self");
  const playerAwayDataStyles = _.get(playerAwayData, "styles", {});

  const backgroundImage = getContentById("defaultBackground", options);
  const backgroundMask = getContentById("defaultBackgroundMask", options);

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""}`}
    >
      <Backgrounds
        bg1={parseMediaUrl(backgroundImage.url)}
        bg2={parseMediaUrl(backgroundMask.url)}
        color1={color1}
        color2={color2}
      />
      <TemplateBlock>
        {/*<Player id={_.get(playerData, 'id', null)} ref={playerRef} image={_.get(playerData, 'value.url', null)} primaryColor={color1}*/}
        {/*        backgroundColor={color2} styles={_.get(playerData, 'styles', null)}/>*/}

        <div className={"background-light"} />

        <div id="header" className={"header"}>
          <div className={"score-vs-block"} style={{ backgroundColor: color1 }}>
            {renderScoreOrVs(fields)}
          </div>

          <div className={"home-team-block"}>
            <div className="HomeTeamLogo">
              {renderLogo(_.get(teamHomeData, "value.url", null))}
              <div className="HomeTeamName">
                {_.get(teamHomeData, "value.data.name", null)}
              </div>
            </div>
          </div>

          <div className={"away-team-block"}>
            <div className="AwayTeamLogo">
              {renderLogo(_.get(teamAwayData, "value.url", null))}
              <div className="AwayTeamName">
                {_.get(teamAwayData, "value.data.name", null)}
              </div>
            </div>
          </div>

          <div className={"headline-block"} style={{ backgroundColor: color1 }}>
            {getContentById("headline", fields) && (
              <div className="headline">
                <div>{getContentById("headline", fields)}</div>
              </div>
            )}
          </div>
        </div>

        {/*<Player ref={refs.playerHome} id={"playerHome"} side={'home'} data={playerHomeData} color1={color1} styles={playerHomeDataStyles}/>*/}
        <Stats fields={fields} primaryColor={color1} backgroundColor={color2} />
        {/*<Player ref={refs.playerAway} id={"playerAway"} side={'away'} data={playerAwayData} color1={color1} styles={playerAwayDataStyles}/>*/}
      </TemplateBlock>
    </div>
  );
};

const renderScoreOrVs = (fields) => {
  const homeScore = getContentById("homeScore", fields);
  const awayScore = getContentById("awayScore", fields);

  if (homeScore && awayScore) {
    return (
      <div className="vs score">
        {homeScore}:{awayScore}
      </div>
    );
  }

  return <div className="vs">VS</div>;
};

const renderLogo = (key) => {
  return <ImageOrVideo src={parseMediaUrl(key)} />;
};

export default Index;
