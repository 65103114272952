const main = {
  apiUrl: "https://api-showcase.spovizz.cloud.vigitalsolutions.com",
  frontendUrl: "https://showcase.spovizz.cloud.vigitalsolutions.com/frontend",
  videoUrl: "https://video.spovizz.com",
  client: "showcase_live",
};

const config = {
  ...main,
  testMode: false,
  appName: "Spo|Vizz",
  screenshotsUrl: `${main.apiUrl}/screenshots/[[TEMPLATE]]/[[UUID]]/[[TYPE]].jpg`,
  chatbox: {
    active: false,
  },
  refreshVideoInterval: 10000,
  dashboardTemplatesRefreshInterval: 5000,
  clientBodyClass: "app-opta",
};

export default config;
