import { clearTimeouts, engine as anime } from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;

  const timeline = anime.timeline();
  $(`#${id} .headline`).css("opacity", 0);
  $(`#${id} .live-matchdays-container`).css("opacity", 0);
  $(`#${id} .stroke-shadow`).css("opacity", 0);

  const params = {};

  if (format === "1920x1080") {
    params.headlineTop = 400;
    params.headlineScale = 1.5;
  } else if (format === "1080x1920") {
    // params.headlineTop = 650
    // params.headlineScale = 1.3
  } else if (format === "1200x1500") {
    // params.headlineTop = 600
    // params.headlineScale = 1.1
  }

  anime
    .timeline({
      targets: `#${id} .headline`,
      easing: "easeInOutQuint",
      delay: 100,
    })
    .add({
      scale: [0, params.headlineScale],
      duration: 900,
      opacity: [1, 1],
    })
    .add({
      scale: [params.headlineScale, 1.0],
      top: [params.headlineTop, $(`#${id} .headline`).css("top")],
      duration: 600,
      delay: 100,
      opacity: [1, 1],
    });

  const timeout1 = setTimeout(() => {
    // anime({
    //   targets: [`#${id} .headline`],
    //   duration: 800,
    //   easing: "easeOutQuint",
    //   top: 50,
    //   fontSize: 90,
    //   opacity: [1, 1],
    //   // rotateZ: [22, 22],
    //   delay: 0
    // });

    anime({
      targets: [`#${id} .live-matchdays-container`],
      duration: 800,
      easing: "easeOutQuint",
      height: [0, $(`#${id} .live-matchdays-container`).height() + "px"],
      // rotateZ: [22, 22],
      opacity: [1, 1],
      delay: 0,
    });

    anime({
      targets: [`#${id} .stroke-shadow`],
      easing: "easeInOutQuint",
      opacity: [
        {
          value: [0, 1],
          duration: 800,
          delay: 0,
          easing: "easeInOutQuart",
        },
        {
          value: [1, 0],
          duration: 1200,
          // easing: 'easeInOutQuart'
        },
      ],
      scale: {
        value: [0.7, 1],
        duration: 1000,
        delay: 100,
        easing: "easeInOutQuart",
      },
      loop: true,
    });
  }, 1350);

  timeouts.push(timeout1);
  // timeouts.push(timeout2);

  return {
    timeline: timeline,
    timeouts: timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
