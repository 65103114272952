import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/adler/adlermatchstatswise";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import {
  getPlayerStats,
  getTeamStats,
} from "../../../../shared/utils/opta/statsHandler";
import countries from "../../../../../language/de/countries.json";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`)
        .get(0)
        .addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  // const nextGame = _.get(dynamic, "nextGame");
  const matchInfo = _.get(dynamic, "matchInfo");
  const matchday = _.get(dynamic, "matchday");
  const matchStats = _.get(dynamic, "matchStats");
  // const teamDataStyles = _.get(teamData, "styles", {});

  const thisGameDate = new Date(matchInfo?.date + matchInfo?.time);

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const teamHomeAlpha2 = getOptaCountryCode(homeTeam?.name);
  const teamAwayAlpha2 = getOptaCountryCode(awayTeam?.name);

  const scores = _.get(matchStats, "liveData.matchDetails.scores.total");

  const teamStats = {
    home: _.get(
      dynamic,
      "matchStats.teamstatistics.homeTeamStatistics.totalStatistics",
    ),
    away: _.get(
      dynamic,
      "matchStats.teamstatistics.awayTeamStatistics.totalStatistics",
    ),
  };

  _.mixin({
    deeply: function (map) {
      return function (obj, fn) {
        return map(
          _.mapValues(obj, function (v) {
            return _.isPlainObject(v) ? _.deeply(map)(v, fn) : v;
          }),
          fn,
        );
      };
    },
  });

  const parsedStats = () => {
    const homeStats = [];
    const awayStats = [];
    const teamStatsCombined = [];

    _.map(teamStats?.away, (stat1, key1) => {
      _.map(stat1, (stat2, key2) => {
        if (typeof stat2 !== "object") {
          awayStats[key2] = stat2;
        }
      });
    });

    _.map(teamStats?.home, (stat1, key1) => {
      _.map(stat1, (stat2, key2) => {
        if (typeof stat2 !== "object") {
          homeStats[key2] = stat2;
          teamStatsCombined.push({
            key: key2,
            home: stat2,
            away: awayStats?.[key2] ?? 0,
          });
        }
      });
    });

    return teamStatsCombined;
  };

  const goalStats = () => {
    return _.get(dynamic, "matchStats.goals.goals");
  };

  const playerStats = () => {
    return _.get(dynamic, "matchStats.skaterstatistics.skaterStatistics");
  };

  const teamStatsCombined = parsedStats();
  const goalStatsCombined = goalStats();
  const playerStatsCombined = playerStats();
  const playersCombined = _.get(dynamic, "players");

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={assets.bg} />

        <div className={"wc-content"}>
          <div className={"wc-bg-content"}></div>

          <div className={"headline-wise slide-1-head"}>Team Stats</div>
          <div className={"headline-wise slide-2-head"}>Goals</div>
          <div className={"headline-wise slide-3-head"}>Player Stats</div>

          <div className={"wise-content"}>
            <div className={"slide-1"}>
              <div className={"team-stats stats-1"}>
                {teamStatsCombined.map((statRow, index) => {
                  return (
                    <div key={statRow.key} className={"stat-row"}>
                      <div className={"stat-home"}>{statRow?.home || "-"}</div>
                      <div className={"stat-label"}>{statRow?.key}</div>
                      <div className={"stat-away"}>{statRow?.away || "-"}</div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={"slide-2"}>
              <div className={"simple-table"}>
                <header>
                  <div className="col">Team Side</div>
                  <div className="col">shooter</div>
                  <div className="col">Score</div>
                  <div className="col">expectedGoals</div>
                  <div className="col">shooterSpeed</div>
                  <div className="col">shotAreaId</div>
                  <div className="col">speed</div>
                  <div className="col">shotDirection</div>
                </header>
                <div className={"table-content"}>
                  {_.map(goalStatsCombined, (goalRow, index) => {
                    const player = _.find(playersCombined, {
                      id: goalRow.shot?.shooter,
                    });
                    return (
                      <div key={goalRow.secondsFromPeriodStart} className="row">
                        <div className="col">{goalRow.team}</div>
                        <div className="col">
                          {player?.surname ?? goalRow.shot?.shooter}
                        </div>
                        <div className="col">
                          {goalRow.homeScore}:{goalRow.awayScore}
                        </div>
                        <div className="col">{goalRow.shot?.expectedGoals}</div>
                        <div className="col">{goalRow.shot?.shooterSpeed}</div>
                        <div className="col">{goalRow.shot?.shotAreaId}</div>
                        <div className="col">{goalRow.shot?.speed}</div>
                        <div className="col">
                          H:{goalRow.shot?.shotDirection?.horizontal?.[0]}|
                          {goalRow.shot?.shotDirection?.horizontal?.[1]},V:
                          {goalRow.shot?.shotDirection?.vertical?.[0]}|
                          {goalRow.shot?.shotDirection?.vertical?.[1]}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className={"slide-3"}>
              <div className={"simple-table"}>
                <header>
                  <div className="col">Team</div>
                  <div className="col">Player</div>
                  <div className="col">avSpeedWithPuck</div>
                  <div className="col">distanceTravelled</div>
                  <div className="col">timeOnIce</div>
                  <div className="col">topSpeed</div>
                  <div className="col">successfulPasses</div>
                </header>
                <div className={"table-content"}>
                  {_.map(playerStatsCombined, (playerRow, index) => {
                    const player = _.find(playersCombined, {
                      id: playerRow.player,
                    });
                    const skatingStatistics = _.get(
                      playerRow,
                      "totalStatistics.skatingStatistics",
                    );
                    const passStatistics = _.get(
                      playerRow,
                      "totalStatistics.passStatistics",
                    );
                    return (
                      <div key={playerRow.player} className="row">
                        <div className="col">{playerRow.team}</div>
                        <div className="col">
                          {player?.surname ?? playerRow?.player}
                        </div>
                        <div className="col">
                          {skatingStatistics?.averageSpeedWithPuck}
                        </div>
                        <div className="col">
                          {skatingStatistics?.distanceTravelled}
                        </div>
                        <div className="col">
                          {skatingStatistics?.timeOnIce}
                        </div>
                        <div className="col">{skatingStatistics?.topSpeed}</div>
                        <div className="col">
                          {passStatistics?.successfulPasses}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `circle_center`);
};

export default Index;
