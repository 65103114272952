import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/customranking";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../language/de/countries.json";
import { useTranslation } from "react-i18next";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import ProgressBar from "../../../../shared/components/ProgressBar";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const matchInfo = _.get(dynamic, "matchInfo");
  const topScorers = _.get(dynamic, "topScorers");
  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const tableData = [];

  const tableFooter = getContentById("tableFooter", fields) || "";
  const headline = getContentById("headline", fields) || "";
  const col0 = getContentById("col0Name", fields);
  const col1 = getContentById("col1Name", fields);
  const col2 = getContentById("col2Name", fields);
  const col3 = getContentById("col3Name", fields);
  const col4 = getContentById("col4Name", fields);

  tableData.push([
    getContentById("row10", fields),
    getContentById("row11", fields),
    getContentById("row12", fields),
    getContentById("row13", fields),
    getContentById("row14", fields),
  ]);

  tableData.push([
    getContentById("row20", fields),
    getContentById("row21", fields),
    getContentById("row22", fields),
    getContentById("row23", fields),
    getContentById("row24", fields),
  ]);

  tableData.push([
    getContentById("row30", fields),
    getContentById("row31", fields),
    getContentById("row32", fields),
    getContentById("row33", fields),
    getContentById("row34", fields),
  ]);

  tableData.push([
    getContentById("row40", fields),
    getContentById("row41", fields),
    getContentById("row42", fields),
    getContentById("row43", fields),
    getContentById("row44", fields),
  ]);
  tableData.push([
    getContentById("row50", fields),
    getContentById("row51", fields),
    getContentById("row52", fields),
    getContentById("row53", fields),
    getContentById("row54", fields),
  ]);
  tableData.push([
    getContentById("row60", fields),
    getContentById("row61", fields),
    getContentById("row62", fields),
    getContentById("row63", fields),
    getContentById("row64", fields),
  ]);

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const teamSide = getContentById("selectedSide", fields);

  const targetTeamId = teamSide === "home" ? homeTeam?.id : awayTeam?.id;

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${
        animation ? "animation" : ""
      } side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle />*/}

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.insight_zone_logo} />
          </div>

          <div className={"teilnahmen-note"}>{tableFooter}</div>

          <div className={"group-table"}>
            <div className={"group-label green-glow"}>{headline}</div>
            <div className={"group-content"}>
              <div className={"table-head"}>
                <div className={"col-pos"}>{col0}</div>
                <div className={"col-team"}>{col1}</div>
                <div className={"col-points"}>{col2}</div>
                <div className={"col-points"}>{col3}</div>
                {col4 && <div className={"col-points"}>{col4}</div>}
              </div>
              {tableData.map((row, index) => {
                const isValidCountry = getOptaCountryCode(
                  _.upperFirst(_.lowerCase(row[1])),
                );
                return (
                  <div key={row[1] + index} className={"table-row"}>
                    <div className={"col-pos"}>{row[0]}.</div>
                    <div className={"col-team"}>
                      {isValidCountry && (
                        <div className={"col-logo"}>
                          <img
                            className="team-logo"
                            src={`/images/countries/svg/${isValidCountry}.svg`}
                          />
                        </div>
                      )}
                      {t(row[1], { ns: "countries" })}
                    </div>
                    <div className={"col-points"}>{row[2]}</div>
                    <div className={"col-points"}>{row[3]}</div>
                    {row[4] && <div className={"col-points"}>{row[4]}</div>}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
