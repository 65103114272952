import _ from "lodash";

const assets = {
  _conditional: (data) => {
    const { format, apiData } = data;
    const dynamic = _.get(apiData, "data.dynamic");
    const side = _.get(dynamic, "teamSide");

    let mainVideoBg = null;

    if (format === "1200x1500") {
      mainVideoBg = `mainbackground_1200_${side}`;
    }

    if (format === "1920x1080") {
      mainVideoBg = `mainbackground_1920_${side}`;
    }

    return [assets[mainVideoBg]];
  },
  mainbackground_1200_home: require("./videos/Background_03_LINEUP_Home_4-5.webm"),
  mainbackground_1200_away: require("./videos/Background_03_LINEUP_Away_4-5.webm"),
  mainbackground_1920_home: require("./videos/Background_03_LINEUP_Home_16-9.webm"),
  mainbackground_1920_away: require("./videos/Background_03_LINEUP_Away_16-9.webm"),
  sap_presents: require("../../../assets/logos/sap-presents.png"),
  mannheimer_presents: require("../../../assets/logos/mannheimer-presents.png"),
  penny_del: require("../../../assets/logos/penny-del-logo.png"),
};

export default assets;
