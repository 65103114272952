import {
  clearTimeouts,
  engine as anime,
} from "../../../../../animations/index";
import { scalePercentToRange } from "../../../../shared/utils/numbers";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;
  const zones = props.data.data.dynamic.attackingZones;

  const timeline = anime.timeline();

  const templateDuration =
    _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0;
  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200,
  });

  anime({
    targets: [`#${id} .comp-match-score-widget`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .versus-info`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .team-logo-container.home`],
    duration: 1000,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 700,
  });

  anime({
    targets: [`#${id} .team-logo-container.away`],
    duration: 1000,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 700,
  });

  anime({
    targets: [`#${id} .comp-arrow-container`],
    duration: 700,
    easing: "easeOutQuint",
    translateY: [1000, 0],
    opacity: [1, 1],
    delay: anime.stagger(150),
  });

  for (const zone of ["leftZone", "centralZone", "rightZone"]) {
    const minPercentHeight = 8;
    const maxPercentHeight = 95;

    const zoneTarget = {
      value: "0%",
      height: `${minPercentHeight}%`,
    };

    const el = $(`#${id} #${zone}`);
    const valueEl = $(`#${id} #${zone} .value`);
    anime({
      duration: 1200,
      targets: zoneTarget,
      value: zones[zone],
      height: `${scalePercentToRange(zones[zone], minPercentHeight, maxPercentHeight)}%`,
      round: 1,
      easing: "linear",
      delay: 1000,
      update: function () {
        valueEl.html(zoneTarget.value);
        el.height(zoneTarget.height);
      },
    });
  }

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
