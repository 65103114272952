import { clearTimeouts, engine as anime } from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;

  const timeline = anime.timeline();

  $(`#${id} .team-logo-container`).css("opacity", 0);
  $(`#${id} .number-of-viewers`).css("opacity", 0);
  $(
    `#${id} .statistics-block .home-value, #${id} .statistics-block .away-value`,
  ).css("opacity", 0);
  $(`#${id} .label-container`).css("opacity", 0);
  $(`#${id} .stat-line-away-container, #${id} .stat-line-home-container`).css(
    "width",
    0,
  );
  $(`#${id} .custom-background-overflow`).css("opacity", 0);

  anime({
    targets: [`#${id} .custom-background-overflow`],
    duration: 3000,
    easing: "easeOutQuint",
    opacity: [0, 1],
    scale: [
      { value: [1.8, 1] },
      { value: [1, 1.1], duration: 12000, easing: "linear" },
    ],
    delay: 200,
  });

  anime({
    targets: [`#${id} .team-logo-container.home`],
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    translateX: [-800, 0],
    delay: 1100,
  });

  anime({
    targets: [`#${id} .team-logo-container.away`],
    duration: 1000,
    easing: "easeOutQuint",
    opacity: [1, 1],
    translateX: [800, 0],
    delay: 1100,
  });

  anime({
    targets: [$(`#${id} .home-scorers > div`).toArray()],
    duration: 700,
    easing: "easeInQuint",
    opacity: [0, 1],
    // rotateZ: ["22deg", 0],
    delay: anime.stagger(150, { start: 1000 }),
  });

  anime({
    targets: [$(`#${id} .away-scorers > div`).toArray()],
    duration: 700,
    easing: "easeInQuint",
    opacity: [0, 1],
    // rotateZ: ["22deg", 0],
    delay: anime.stagger(150, { start: 1000 }),
  });

  anime({
    targets: [`#${id} .periods-container > div`],
    duration: 600,
    easing: "easeOutQuint",
    opacity: [0, 1],
    // rotateZ: ["22deg", 0],
    delay: anime.stagger(100, { start: 1100 }),
  });

  anime({
    targets: $(
      `#${id} .statistics-block .home-value, #${id} .statistics-block .away-value`,
    ).toArray(),
    duration: 700,
    easing: "easeOutQuint",
    opacity: [0, 1],
    delay: anime.stagger(100, { start: 1000 }),
  });

  anime({
    targets: $(`#${id} .label-container`).toArray(),
    duration: 700,
    easing: "easeOutQuint",
    opacity: [0, 1],
    delay: anime.stagger(100, { start: 900 }),
  });

  anime({
    targets: [`#${id} .score-home`],
    duration: 600,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    // rotateZ: ["22deg", 0],
    delay: 900,
  });

  anime({
    targets: [`#${id} .score-away`],
    duration: 600,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    // rotateZ: ["22deg", 0],
    delay: 900,
  });

  const timeout1 = setTimeout(() => {
    // let teamLogoParams = {
    //   size: null,
    //   left: null,
    //   right: null
    // };
    //
    // if (format === "1920x1080") {
    //   teamLogoParams = {
    //     top: 0,
    //     left: 100,
    //     size: 230
    //   }
    // } else if (format === "1200x1500") {
    //   teamLogoParams = {
    //     top: 385,
    //     left: 325,
    //     size: 200
    //   }
    // }
    // anime({
    //   targets: [`#${id} .teams-block .home`],
    //   duration: 1200,
    //   easing: "easeOutQuint",
    //   top: teamLogoParams.top,
    //   left: teamLogoParams.left,
    //   width: teamLogoParams.size,
    //   height: teamLogoParams.size,
    //   delay: 0
    // });
    //
    // anime({
    //   targets: [`#${id} .teams-block .away`],
    //   duration: 1200,
    //   easing: "easeOutQuint",
    //   top: teamLogoParams.top,
    //   right: teamLogoParams.left,
    //   width: teamLogoParams.size,
    //   height: teamLogoParams.size,
    //   delay: 0
    // });
  }, 1000);

  anime({
    targets: [`#${id} .number-of-viewers`],
    duration: 850,
    easing: "easeOutQuint",
    opacity: [0, 1],
    translateY: [-1000, 0],
    delay: 500,
  });

  // if (format === "1200x1500") {
  //
  //   anime({
  //     targets: [`#${id} .teams-block .home img`],
  //     duration: 600,
  //     easing: "easeOutQuint",
  //     opacity: [1, 1],
  //     translateX: [-2000, 0],
  //     delay: 500
  //   });
  //
  //   anime({
  //     targets: [`#${id} .teams-block .away img`],
  //     duration: 600,
  //     easing: "easeOutQuint",
  //     opacity: [1, 1],
  //     translateX: [2000, 0],
  //     delay: 500
  //   });
  //
  //
  //   const timeout1 = setTimeout(() => {
  //     anime({
  //       targets: [`#${id} .teams-block .home img`],
  //       duration: 600,
  //       easing: "easeOutQuint",
  //       opacity: [1, 1],
  //       left: 0,
  //       delay: 0
  //     });
  //
  //     anime({
  //       targets: [`#${id} .teams-block .away img`],
  //       duration: 600,
  //       easing: "easeOutQuint",
  //       opacity: [1, 1],
  //       right: 0,
  //       delay: 0
  //     });
  //
  //     anime({
  //       targets: [`#${id} .teams-block .home-name`],
  //       duration: 600,
  //       easing: "easeOutQuint",
  //       opacity: [0.6, 0.6],
  //       translateX: [-2000, 0],
  //       // rotateZ: [270, 270],
  //       delay: 100
  //     });
  //
  //     anime({
  //       targets: [`#${id} .teams-block .away-name`],
  //       duration: 600,
  //       easing: "easeOutQuint",
  //       opacity: [0.6, 0.6],
  //       translateX: [2000, 0],
  //       // rotateZ: [270, 270],
  //       delay: 100
  //     });
  //
  //     anime({
  //       targets: [`#${id} .statistics-block .home-value`],
  //       duration: 800,
  //       easing: "easeOutQuint",
  //       opacity: [1, 1],
  //       scale: [0, 1],
  //       delay: anime.stagger(100),
  //     });
  //
  //     anime({
  //       targets: [`#${id} .statistics-block .away-value`],
  //       duration: 800,
  //       easing: "easeOutQuint",
  //       opacity: [1, 1],
  //       scale: [0, 1],
  //       delay: anime.stagger(100),
  //     });
  //
  //     anime({
  //       targets: [`#${id} .home-scorers`],
  //       duration: 700,
  //       easing: "easeOutQuint",
  //       translateX: [-1000, 0],
  //       opacity: [1, 1],
  //       delay: 0
  //     });
  //
  //     anime({
  //       targets: [`#${id} .away-scorers`],
  //       duration: 700,
  //       easing: "easeOutQuint",
  //       translateX: [1000, 0],
  //       opacity: [1, 1],
  //       delay: 0
  //     });
  //
  //     anime({
  //       targets: [`#${id} .score-home`],
  //       duration: 800,
  //       easing: "easeOutQuint",
  //       scale: [0, 1],
  //       opacity: [1, 1],
  //       delay: 0
  //     });
  //
  //     anime({
  //       targets: [`#${id} .score-away`],
  //       duration: 800,
  //       easing: "easeOutQuint",
  //       scale: [0, 1],
  //       opacity: [1, 1],
  //       // rotateZ: ["22deg", 0],
  //       delay: 0
  //     });
  //
  //     anime({
  //       targets: [`#${id} .periods-container > div`],
  //       duration: 800,
  //       easing: "easeOutQuint",
  //       translateY: [800, 0],
  //       opacity: [1, 1],
  //       // rotateZ: ["22deg", 0],
  //       delay: anime.stagger(100)
  //     });
  //
  //   }, 1200);
  //
  //   const timeout2 = setTimeout(() => {
  //     anime({
  //       targets: [`#${id} .stat-line-away-container`],
  //       duration: 1200,
  //       easing: "easeOutQuint",
  //       width: [0, 335],
  //       delay: anime.stagger(100)
  //     });
  //
  //     anime({
  //       targets: [`#${id} .stat-line-home-container`],
  //       duration: 1200,
  //       easing: "easeOutQuint",
  //       width: [0, 335],
  //       delay: anime.stagger(100)
  //     });
  //
  //   }, 1500);
  //
  //   timeouts.push(timeout1);
  //   timeouts.push(timeout2);
  // } else if (format === "1920x1080") {
  //
  //   anime({
  //     targets: [`#${id} .statistics-block .stat-entry-block`],
  //     duration: 650,
  //     easing: "easeOutQuint",
  //     opacity: [1, 1],
  //     translateY: [1000, 0],
  //     delay: 750
  //   });
  //
  //   const timeout1 = setTimeout(() => {
  //     anime({
  //       targets: [`#${id} .teams-block .home img`],
  //       duration: 600,
  //       easing: "easeOutQuint",
  //       opacity: [1, 1],
  //       left: 0,
  //       delay: 0
  //     });
  //
  //     anime({
  //       targets: [`#${id} .teams-block .away img`],
  //       duration: 600,
  //       easing: "easeOutQuint",
  //       opacity: [1, 1],
  //       right: 0,
  //       delay: 0
  //     });
  //
  //     anime({
  //       targets: [`#${id} .teams-block .home-name`],
  //       duration: 600,
  //       easing: "easeOutQuint",
  //       opacity: [0.4, 0.4],
  //       translateX: [-2000, 0],
  //       rotateZ: [270, 270],
  //       delay: 100
  //     });
  //
  //     anime({
  //       targets: [`#${id} .teams-block .away-name`],
  //       duration: 600,
  //       easing: "easeOutQuint",
  //       opacity: [0.4, 0.4],
  //       translateX: [2000, 0],
  //       rotateZ: [270, 270],
  //       delay: 100
  //     });
  //
  //     anime({
  //       targets: [`#${id} .statistics-block .home-value`],
  //       duration: 800,
  //       easing: "easeOutQuint",
  //       opacity: [1, 1],
  //       scale: [0, 1],
  //       delay: anime.stagger(100),
  //     });
  //
  //     anime({
  //       targets: [`#${id} .statistics-block .away-value`],
  //       duration: 800,
  //       easing: "easeOutQuint",
  //       opacity: [1, 1],
  //       scale: [0, 1],
  //       delay: anime.stagger(100),
  //     });
  //
  //   }, 1200);
  //
  const timeout2 = setTimeout(() => {
    if (format === "1200x1500") {
      anime({
        targets: [`#${id} .stat-line-away-container`],
        duration: 1200,
        easing: "easeOutQuint",
        width: [0, 320],
        delay: anime.stagger(100),
      });

      anime({
        targets: [`#${id} .stat-line-home-container`],
        duration: 1200,
        easing: "easeOutQuint",
        width: [0, 320],
        delay: anime.stagger(100),
      });
    } else if (format === "1920x1080") {
      anime({
        targets: [`#${id} .stat-line-away-container`],
        duration: 1200,
        easing: "easeOutQuint",
        width: [0, 500],
        delay: anime.stagger(100),
      });

      anime({
        targets: [`#${id} .stat-line-home-container`],
        duration: 1200,
        easing: "easeOutQuint",
        width: [0, 500],
        delay: anime.stagger(100),
      });
    }
  }, 1500);
  //
  //   timeouts.push(timeout1);
  //   timeouts.push(timeout2);
  // }

  return {
    timeline: timeline,
    timeouts: timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
