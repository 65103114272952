import { clearTimeouts, engine as anime } from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;

  const timeline = anime.timeline();

  const templateDuration =
    _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0;
  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  $(
    `#${id} .layer-positions .shot, #${id} .layer-shots .shot, #${id} .stat-row, #${id} .stat-separator`,
  ).css("opacity", 0);
  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 200,
  });

  anime({
    targets: [`#${id} .comp-match-score-widget`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .wc-headline`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [2000, 0],
    opacity: [1, 1],
    delay: 400,
  });

  anime({
    targets: [`#${id} .versus-info`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 400,
  });

  anime({
    targets: [`#${id} .goalmouth-content`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .field-content`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [2000, 0],
    opacity: [1, 1],
    delay: 600,
  });

  anime({
    targets: [`#${id} .team-logo-container`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-2000, 0],
    opacity: [1, 1],
    delay: 800,
  });

  const timeout1 = setTimeout(() => {
    anime({
      targets: [`#${id} .layer-shots .shot.color-goal`],
      duration: 800,
      easing: "easeInOutCubic",
      translateY: [1200, 0],
      skewY: ["60deg", 0],
      opacity: [1, 1],
      delay: anime.stagger(200),
      complete: () => {
        anime({
          targets: [`#${id} .layer-shots .shot:not(.color-goal)`],
          duration: 800,
          easing: "easeInOutCubic",
          translateY: [1200, 0],
          skewY: ["60deg", 0],
          opacity: [1, 1],
          delay: anime.stagger(200),
        });
      },
    });

    anime({
      targets: [`#${id} .layer-positions .shot.color-goal`],
      duration: 800,
      easing: "easeInOutCubic",
      translateY: [1200, 0],
      skewY: ["60deg", 0],
      opacity: [1, 1],
      delay: anime.stagger(200),
      complete: () => {
        anime({
          targets: [`#${id} .layer-positions .shot:not(.color-goal)`],
          duration: 1000,
          easing: "easeInOutCubic",
          translateY: [1200, 0],
          skewY: ["60deg", 0],
          opacity: [1, 1],
          delay: anime.stagger(200),
        });
      },
    });

    anime({
      targets: [`#${id} .stat-row, #${id} .stat-separator`],
      duration: 1000,
      easing: "easeOutQuint",
      translateX: [2000, 0],
      opacity: [1, 1],
      delay: anime.stagger(200),
    });
  }, 1200);

  timeouts.push(timeout1);

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
