import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/facts";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../language/de/countries.json";
import { useTranslation } from "react-i18next";
import Image from "../../../../shared/components/Image";
import { toIsoDateString } from "../../../../shared/utils/functions";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const headline = getContentById("headline", fields);
  const subHeadline = getContentById("subHeadline", fields);
  const fact1 = getContentById("fact1", fields);
  const fact2 = getContentById("fact2", fields);
  const fact3 = getContentById("fact3", fields);
  const type = getContentById("type", fields);
  const isMatchType = type === "match";

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  // const nextGame = _.get(dynamic, "nextGame");
  const matchInfo = _.get(dynamic, "matchInfo");
  // const teamDataStyles = _.get(teamData, "styles", {});

  const thisGameDate = new Date(
    toIsoDateString(matchInfo?.date, matchInfo?.time),
  );

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  // const teamHomeAlpha2 = getOptaCountryCode(homeTeam?.name, homeTeam?.shortName, homeTeam?.code);
  // const teamAwayAlpha2 = getOptaCountryCode(awayTeam?.name, awayTeam?.shortName, awayTeam?.code);
  const teamHomeAlpha2 = homeTeam?.id;
  const teamAwayAlpha2 = awayTeam?.id;

  const scores = _.get(dynamic, "liveData.matchDetails.scores.total");

  const matchDetails = _.get(dynamic, "liveData.matchDetails");
  const matchStatus = _.get(matchDetails, "matchStatus");

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle />*/}

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.insight_zone_logo} />
          </div>

          <MatchScoreWidget
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            scores={scores}
            matchStatus={matchStatus}
            matchDetails={matchDetails}
          />

          <div className={"main-title green-glow"}>{headline}</div>
          {subHeadline && type === "global" && (
            <div className={"sub-title green-glow"}>{subHeadline}</div>
          )}

          <div className={"info-block"}>
            {subHeadline && type == !"global" && (
              <div className={"sub-title green-glow"}>{subHeadline}</div>
            )}
            {isMatchType && (
              <div className={"teams-block"}>
                <div className={"team-vs"}>
                  <div className={"versus"}>vs</div>
                </div>
                <div className={"team-logo-container home"}>
                  <Image
                    className="team-logo"
                    src={`/images/dynamic/soccer/team/${teamHomeAlpha2}.png`}
                  />
                  <div className={"team-name away"}>
                    {t(homeTeam?.name, { ns: "countries" })}
                  </div>
                </div>
                <div className={"team-logo-container away"}>
                  <Image
                    className="team-logo"
                    src={`/images/dynamic/soccer/team/${teamAwayAlpha2}.png`}
                  />
                  <div className={"team-name away"}>
                    {t(awayTeam?.name, { ns: "countries" })}
                  </div>
                </div>
              </div>
            )}

            <div className={"facts-block"}>
              <div
                className="facts fact-1"
                dangerouslySetInnerHTML={{ __html: fact1 }}
              ></div>
              <div
                className="facts fact-2"
                dangerouslySetInnerHTML={{ __html: fact2 }}
              ></div>
              <div
                className="facts fact-3"
                dangerouslySetInnerHTML={{ __html: fact3 }}
              ></div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
