export default (ext) => {
  if (!ext) {
    return null;
  }

  if (
    ext.indexOf(".jpg") > -1 ||
    ext.indexOf(".jpeg") > -1 ||
    ext.indexOf(".png") > -1 ||
    ext.indexOf(".gif") > -1
  ) {
    return "image";
  } else if (
    ext.indexOf(".mp4") > -1 ||
    ext.indexOf(".webm") > -1 ||
    ext.indexOf(".ogv") > -1
  ) {
    return "video";
  } else if (ext.indexOf(".mp3") > -1) {
    return "audio";
  } else {
    return "image";
  }
};
