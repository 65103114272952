import timelineMainBg from "../../../assets/timeline/timeline_bg.jpeg";
import { useEffect } from "react";

export default function PagesContainer(props) {
  const { id, template } = props._urlParams;
  const { format } = props._searchParams;
  const formatClass = format ? `${format}` : `1920x1080`;

  return <div className={`f-${formatClass}`}>{props.children}</div>;
}
