import React, { useState, useEffect } from "react";
import { useHistory, BrowserRouter, useRouteMatch } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import Layouts from "./layouts/Index";

import AuthService from "./services/auth.service";

// import 'antd/dist/antd.css';
import "antd/dist/reset.css";

import backendConfig from "./config/backend";

// Start the mocking conditionally.
// if (process.env.NODE_ENV === 'development') {
//     const { worker } = require('./mocks/browser')
//     worker.start()
// }

import "./loadScripts";

function App(props) {
  const history = useHistory();

  useEffect(() => {
    document.body.classList.add(backendConfig.clientBodyClass);
  }, []);

  // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  // const [showAdminBoard, setShowAdminBoard] = useState(false);
  // const [currentUser, setCurrentUser] = useState(undefined);
  //
  // console.log('App')
  //
  // useEffect(() => {
  //     const user = AuthService.getCurrentUser();
  //
  //     if (user) {
  //         setCurrentUser(user.user);
  //         setShowModeratorBoard(user.user.role.includes("user") || user.user.role.includes("admin"));
  //         setShowAdminBoard(user.user.role.includes("admin"));
  //     }
  // }, []);

  const logout = () => {
    AuthService.logout();
    history.push("/");
  };

  return (
    <BrowserRouter>
      <Layouts />
    </BrowserRouter>
  );
}

export default App;
