const main = {
  apiUrl: "https://api-showcase.spovizz.cloud.vigitalsolutions.com",
  frontendUrl: "https://showcase.spovizz.cloud.vigitalsolutions.com/frontend",
  videoUrl: "https://video.spovizz.com",
  client: "showcase_live",
};

export default {
  ...main,
  testMode: false,
  testData: {
    templateDuration: 4,
  },
  appName: "Spo|Vizz",
  apiUrl: main.apiUrl,
  templateDuration: 5000,
  videoBeginColor: "rgb(0, 0, 255)",
  videoEndColor: "rgb(0, 255, 0)",
  videoBeginColorDuration: 2000,
  videoEndColorDuration: 2000,
  timeline: {
    firstSlideDelay: 500,
    fadeInDuration: 100,
    fadeOutDuration: 500,
    defaultDuration: 15, //in secs, just a backup value
    transitionType: "video", //null
    videoTransitionBefore: 1300, //time when video covering whole screen, so we can start template switching
    videoTransitionAfter: 0, //delay until new template show up, after transition is finished
  },
  showOutroAfter: 12000,
  timelineAutoLinkEnabled: true,
  timelineLinkAutomation: {
    "PRE-MATCH": {
      beforeInMinutes: 40,
    },
    "LIVE-MATCH": {
      event: "start_game",
    },
    "POST-MATCH": {
      afterGameEnd: 5,
    },
  },
  reloadOnTimelineError: true,
};
