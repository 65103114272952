import React from "react";

import "./styles.scss";
import { PlayerTriko } from "../PlayerTriko/Index";

const PitchTactics = React.forwardRef((props, ref) => {
  const {
    image,
    id,
    styles,
    formation = "442",
    players = [],
    trikot = null,
    pitch = null,
    teamId = null,
    side = "home",
    kits = null,
  } = props;

  const className = props.className || "";

  const mainLineup =
    players &&
    players.filter(
      (p) => p?.position !== "Substitute" && p?.formationPlace !== "1",
    );
  const goalKeeper = _.find(players, { formationPlace: "1" });

  //diamond formation fallback
  const finalFormation = formation === "343d" ? "31213" : formation;
  const totalLines = finalFormation.length;

  const getLines = () => {
    const outputLines = [];

    if (!mainLineup.length) {
      return outputLines;
    }

    let output = [];
    let currentLine = 0;
    let currentLinePlayers = 0;

    const gkLine = [goalKeeper];
    outputLines.push(gkLine);

    _.forEach(mainLineup, (p) => {
      let playersPerLine = parseInt(finalFormation[currentLine]);

      if (playersPerLine > currentLinePlayers) {
        output.push(p);
        ++currentLinePlayers;
      }

      if (playersPerLine === currentLinePlayers) {
        currentLinePlayers = 0;
        ++currentLine;
        outputLines.push(output);
        output = [];
      }
    });

    return outputLines.reverse();
  };

  const playerLines = getLines();

  return (
    <div id={id} ref={ref} className={`comp-pitch-tactics ${className}`}>
      <div className={`formation-block f-${formation} ft-${totalLines}`}>
        {pitch && (
          <div className={`lineup-pitch`}>
            <img src={pitch} />
          </div>
        )}
        {playerLines &&
          playerLines.map((line, lineIndex) => {
            return (
              <div
                key={`line-${++lineIndex}`}
                className={`line line-${++lineIndex}`}
              >
                {line.map((p) => {
                  return (
                    <div
                      key={`p-${p?.playerId}`}
                      className={`lineup-player fp-${p?.formationPlace}`}
                    >
                      <div className={"shirt-number"}>{p?.shirtNumber}</div>
                      {/*{trikot && <img src={trikot}/>}*/}
                      <PlayerTriko
                        className={"left-player"}
                        player={p}
                        teamId={teamId}
                        side={side}
                        bgColor={kits?.[side]?.colour1}
                      />
                      <div className={"player-name"}>
                        {p?.shortFirstName?.[0]}. {p?.shortLastName}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
});

export default PitchTactics;
