const assets = {
  _conditional: (data) => {
    const { format, apiData } = data;
    return [assets.mainbackground_1920_home, assets.mainbackground_1920_away];
  },
  mainbackground_1920_home: require("./videos/Background_09_STATS_TEAM_Home_16-9.webm"),
  mainbackground_1920_away: require("./videos/Background_09_STATS_TEAM_Away_16-9.webm"),
  sap_presents: require("../../../assets/logos/sap_white.png"),
  penny_del: require("../../../assets/logos/penny-del-logo.png"),
};

export default assets;
