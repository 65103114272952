const assets = {
  _conditional: (data) => {
    const { format, apiData } = data;
    return [
      assets.mainbackground_1080_home,
      assets.mainbackground_1080_away,
      assets.sap_presents,
      assets.penny_del,
    ];
  },
  mainbackground_1080_home: require("./videos/Background_05_GOAL_Home_9-16.webm"),
  mainbackground_1080_2home: require("./videos/Background_05_GOAL_Home_9-16_2.webm"),
  mainbackground_1080_away: require("./videos/Background_05_GOAL_Away_9-16.webm"),
  mainbackground_1080_2away: require("./videos/Background_05_GOAL_Away_9-16_2.webm"),
  sap_presents: require("../../../assets/logos/sap-presents.png"),
  penny_del: require("../../../assets/logos/penny-del-logo.png"),
  aok_presents: require("../../../assets/logos/aok-presents.png"),
};

export default assets;
