import React, { useEffect, useState } from "react";

import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, { animationCleanUp } from "./animation";

import assets from "./assets";
import { useTranslation } from "react-i18next";
import Image from "../../../../shared/components/Image";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";

import "./style/style.scss";
import "./style/animation.scss";
import Arrow from "./arrow";

const getMainBackground = () => {
  return assets.background_1920;
};

export default function Index({ animation, data, mode, format, ...props }) {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const { t } = useTranslation();

  const templateName = data?.template_name ?? "";
  const id = `${templateName}_${data?.uuid}`;

  const { matchInfo, matchDetails, attackingZones, teamSide } =
    data?.data?.dynamic;
  const { matchStatus, scores } = matchDetails;

  const backgroundImage = getMainBackground(format, teamSide);

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const team = teamSide === "home" ? homeTeam : awayTeam;

  // attackingZones.leftZone = 10;
  // attackingZones.centralZone = 50;
  // attackingZones.rightZone = 100;

  return (
    <div
      id={id}
      className={`T_${templateName} ${animation ? "animation" : ""} side-${teamSide}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />

        <div className="wc-content wc-content-v2">
          <ProgressBar height="8px" />
          <div className="wc-bg-content"></div>

          <div className="qatar-logo">
            <img src={assets.insight_zone_logo} />
          </div>

          <MatchScoreWidget
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            scores={scores.total}
            matchStatus={matchStatus}
            matchDetails={matchDetails}
          />


          <div className={"left-block-side"}>
            <div className="teams-block">
              <div className={`team-logo-container ${teamSide}`}>
                <Image
                  className="team-logo"
                  src={`/images/dynamic/soccer/team/${team.id}.png`}
                />
                <div className={`team-name ${teamSide}`}>
                  {t(team.name, {
                    ns: "countries",
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className={"right-block-side"}>
            <div className="main-header green-glow">
              {t("AttackingZonesHeadline", { keyPrefix: "templates" })}
            </div>

            <div className="versus-info">
              <div className="group">
                {matchInfo?.series?.name.replace(
                  "Group",
                  t("Group", { keyPrefix: "templates" }),
                ) || ""}
              </div>
            </div>

            <div
              className={`attacking-zones`}
              style={{ backgroundImage: `url(${assets.pitch})` }}
            >
              <div className="arrows">
                <Arrow id={"leftZone"} value={attackingZones.leftZone} />
                <Arrow id={"centralZone"} value={attackingZones.centralZone} />
                <Arrow id={"rightZone"} value={attackingZones.rightZone} />
              </div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
}
