import _ from "lodash";
import iso from "iso-3166-1";
import backendConfig from "../../../config/backend";

export const getPlayerPosition = (player) => {
  if (player?.position && player?.position !== "Substitute") {
    return player.position;
  }

  if (player?.subPosition) {
    return player.subPosition;
  }

  return "-";
};
