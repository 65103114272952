import _ from "lodash";
import { globalAssets } from "../../../assets/global/assets";

const assets = {
  ...globalAssets,
  goalmouth: require("../../../assets/wc/images/goalmouth.png"),
  goalmouth_field: require("../../../assets/wc/images/goalmouth_field.png"),
};

export default assets;
