// return index to mark on standing table
// Example output:
//   [
//     {"rowIndex": 5, "rankStatus": "UEFA Europa League"},
//     {"rowIndex": 6, "rankStatus": "UEFA Conference League Qualifiers"},
//     {"rowIndex": 16, "rankStatus": "Relegation Play-off"},
//     {"rowIndex": 17, "rankStatus": "Relegation"}
//   ]
export const findRankStatusChanges = (ranking, statusesToSkip = []) => {
  const changes = [];

  if (ranking.length === 0) return changes;

  let lastIndexOfFirstRankStatus = -1;
  const firstRankStatus = ranking[0]?.rankStatus || "";

  for (let i = 0; i < ranking.length - 1; i++) {
    const currentRankStatus = ranking[i]?.rankStatus || "";
    const nextRankStatus = ranking[i + 1]?.rankStatus || "";

    if (currentRankStatus === firstRankStatus) {
      lastIndexOfFirstRankStatus = i + 1;
    }

    if (
      currentRankStatus !== nextRankStatus &&
      nextRankStatus &&
      !statusesToSkip.includes(nextRankStatus)
    ) {
      changes.push({
        rowIndex: i + 2,
        rankStatus: nextRankStatus,
        relegation: nextRankStatus.includes("Relegation"),
      });
    }
  }

  if (
    firstRankStatus &&
    !statusesToSkip.includes(firstRankStatus) &&
    lastIndexOfFirstRankStatus !== -1
  ) {
    changes.push({
      rowIndex: lastIndexOfFirstRankStatus,
      rankStatus: firstRankStatus,
      relegation: firstRankStatus.includes("Relegation"),
    });
  }

  changes.sort((a, b) => a.rowIndex - b.rowIndex);

  return changes;
};
