import { clearTimeouts, engine as anime } from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;

  const timeline = anime.timeline();
  // $(`#${id} .headline`).css("opacity", 0);
  $(`#${id} .table`).css("opacity", 0);

  const params = {};

  if (format === "1920x1080") {
    params.headlineTop = 380;
    params.headlineScale = 1.5;
  }

  anime
    .timeline({
      targets: `#${id} .headline`,
      easing: "easeInOutQuint",
      delay: 150,
    })
    .add({
      scale: [0, params.headlineScale],
      duration: 800,
      opacity: [1, 1],
    })
    .add({
      scale: [params.headlineScale, 1.0],
      top: [params.headlineTop, $(`#${id} .headline`).css("top")],
      duration: 750,
      delay: 240,
      opacity: [1, 1],
    });

  anime({
    targets: [`#${id} .table`],
    duration: 700,
    easing: "easeInOutQuint",
    height: [0, "945px"],
    // rotateZ: [22, 22],
    opacity: [0, 1],
    delay: 1400,
  });

  return {
    timeline: timeline,
    timeouts: timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
