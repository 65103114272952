import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _get from "lodash/get";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/adler/goal";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from "./assets";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import outroVideo16x9 from "../../../assets/outros/2023/outro_playoffs_16x9.webm";
import outroVideo4x5 from "../../../assets/outros/2023/outro_playoffs_4x5.webm";
import outroVideo9x16 from "../../../assets/outros/2023/outro_playoffs_9x16.webm";
import { usePlayoffsVideo } from "../../../../shared/hooks/usePlayoffsVideo";
import { parseGoal } from "../../../../shared/utils/goals";

require("./style/style.scss");
require("./style/animation.scss");

function convertHockeyTimeToMinutes(timeStr) {
  try {
    let [minutes, seconds] = timeStr.split(":").map(Number);

    if (seconds >= 0 && minutes >= 0) {
      if (seconds > 0) {
        minutes += 1;
      }
      return `${minutes}. Minute`;
    } else {
      throw new Error("Invalid time format");
    }
  } catch (e) {
    return `${timeStr}`;
  }
}

const renderDescriptionBox = (goal, periodOveride, timeOveride) => {
  const goalPeriod = _get(goal, "period", null);
  const goalScoreboard = _get(goal, "scoreboardTime", null);

  let periodString = "";
  let scoreboardString = "";

  if (goalPeriod === "overtime") {
    periodString = "OT";
    scoreboardString = goalScoreboard;
  } else if (goalPeriod === "shootout") {
    periodString = "SO";
  } else {
    periodString = goalPeriod + ". Drittel";
    scoreboardString = goalScoreboard;
  }

  const parsedGoalBalance = parseGoal(goal, false, true);

  return (
    <div className={"goal-description-box"}>
      <div className={"period-name"}>
        {periodOveride ? periodOveride : periodString}
      </div>
      <div className={"period-separator"}>/</div>
      <div className={"period-timestamp"}>
        {timeOveride
          ? timeOveride
          : convertHockeyTimeToMinutes(scoreboardString)}
      </div>
      <div className={"period-balance"}>
        &nbsp;{parsedGoalBalance ? `/ ${parsedGoalBalance}` : ""}
      </div>
    </div>
  );
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  // handle outro video BEGIN
  // usePlayoffsVideo({animation, mode, isTimeline, id})
  // handle outro video END

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      prepareAnimation();
      animationReturn = animationScript({ refs, data, animation, mode, id });
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`)
        .get(0)
        .addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);
  const periodOveride = getContentById("periodName", fields);
  const timeOveride = getContentById("periodTime", fields);
  const playerOveride = getContentById("player", fields);
  const scoreHomeOverride = getContentById("scoreHome", fields);
  const scoreAwayOverride = getContentById("scoreAway", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const player = _.get(dynamic, "assets.player");
  const playerOverride = _.get(dynamic, "assets.playerOverride");
  const eventData = _.get(dynamic, "goal");

  const teamSide = _.get(dynamic, "teamSide");

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));
  const backgroundImage2 = getMainBackground2(
    format,
    _.get(dynamic, "teamSide"),
  );

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${
        animation ? "animation" : ""
      } side-${teamSide}`}
    >
      {/*<img className={"custom-background"} src={parseMediaUrl(customBackground.url)}/>*/}

      <TemplateBlock>
        <div className={"videobg-1"}>
          <Backgrounds bg1={backgroundImage} />
        </div>
        <div className={"videobg-2"}>
          <Backgrounds bg1={backgroundImage2} />
        </div>

        {/*handle outro video BEGIN*/}
        {format === "1920x1080" && (
          <video
            className={"outro-optional-video"}
            controls={false}
            autoPlay={false}
            muted={true}
            loop={false}
          >
            <source src={outroVideo16x9} />
          </video>
        )}

        {format === "1200x1500" && (
          <video
            className={"outro-optional-video"}
            controls={false}
            autoPlay={false}
            muted={true}
            loop={false}
          >
            <source src={outroVideo4x5} />
          </video>
        )}

        {format === "1080x1920" && (
          <video
            className={"outro-optional-video"}
            controls={false}
            autoPlay={false}
            muted={true}
            loop={false}
          >
            <source src={outroVideo9x16} />
          </video>
        )}
        {/*handle outro video END*/}

        {renderDescriptionBox(eventData, periodOveride, timeOveride)}

        {playerOverride ? (
          <>
            <img
              className={"player"}
              src={parseMediaUrl(_.get(playerOverride, "url", null))}
            />
            {/*<div className={"player-shadow-container"}>*/}
            {/*  <img className={"player-shadow"} src={parseMediaUrl(_.get(playerOverride, 'url', null))}/>*/}
            {/*</div>*/}
          </>
        ) : (
          <>
            <img
              className={"player"}
              src={parseMediaUrl(_.get(player, "url", null))}
            />
            {/*<div className={"player-shadow-container"}>*/}
            {/*  <img className={"player-shadow"} src={parseMediaUrl(_.get(player, 'url', null))}/>*/}
            {/*</div>*/}
          </>
        )}

        <img className={"sponsors sponsor-del"} src={assets.penny_del} />

        <div className={"teams-block"}>
          <img
            className={"home"}
            src={parseMediaUrl(_.get(teamHome, "url", null))}
          />
          <div className={"score"}>
            {scoreHomeOverride &&
            scoreHomeOverride > -1 &&
            scoreAwayOverride &&
            scoreAwayOverride > -1
              ? `${scoreHomeOverride}:${scoreAwayOverride}`
              : _.get(eventData, "currentScore", "0:0")}
          </div>
          <img
            className={"away"}
            src={parseMediaUrl(_.get(teamAway, "url", null))}
          />
        </div>

        {/*<div className={"headline"}>TOOOR!</div>*/}

        <div className={"player-name"}>
          <div className={"name"}>
            {playerOverride
              ? _.get(playerOverride, "data.firstname")
              : _.get(player, "data.firstname")}
          </div>
          <div className={"surname"}>
            {playerOverride
              ? _.get(playerOverride, "data.lastname")
              : _.get(player, "data.lastname")}
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `mainbackground_1080_${side}`);
};
const getMainBackground2 = (format, side) => {
  return _.get(assets, `mainbackground_1080_2${side}`);
};

export default Index;
