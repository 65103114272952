const lockMinMax = (value, min, max) => {
  if (value <= min) {
    return min;
  } else if (value >= max) {
    return max;
  }
  return value;
};

export function scalePercentToRange(inputPercent, min, max, scaler = 0.8) {
  if (inputPercent <= min) {
    return min;
  } else if (inputPercent >= max) {
    return max;
  } else {
    return lockMinMax(inputPercent * scaler, min, max);
  }
}
