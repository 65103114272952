import React, { Component, useEffect, useState } from "react";
import { Progress } from "antd";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import VigitalSolutionLogo from "../../../../assets/logo/vigital_solutions.png";
import "./styles.scss";

export default (props) => {
  return (
    <div
      className={"comp-progress-bar progress-animate"}
      data-width={props.value}
      data-duration={props.duration}
      style={{ height: props.height }}
    >
      <Progress
        className={props?.className}
        strokeLinecap="butt"
        percent={props.value ?? 0}
        strokeColor={props?.strokeColor ?? "#4ea1af"}
        trailColor={props?.trailColor ?? "rgba(78, 161, 175, 0.25)"}
        strokeWidth={props.height}
        showInfo={false}
      />
    </div>
  );
};
