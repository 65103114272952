import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/standings";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../language/de/countries.json";
import { useTranslation } from "react-i18next";
import Image from "../../../../shared/components/Image";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import { findRankStatusChanges } from "../../../../shared/functions/standing";
import ProgressBar from "../../../../shared/components/ProgressBar";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const StandingLabel = () => {
  const { t } = useTranslation();
  return (
    <div className={"table-head"}>
      <div className={"col-pos col"}></div>
      <div className={"col-team col"}></div>
      <div className={"col-points col"}>{t("PL", { keyPrefix: "table" })}</div>
      <div className={"col-points col"}>{t("W", { keyPrefix: "table" })}</div>
      <div className={"col-points col"}>{t("D", { keyPrefix: "table" })}</div>
      <div className={"col-points col"}>{t("L", { keyPrefix: "table" })}</div>
      <div className={"col-diff col"}>{t("GD", { keyPrefix: "table" })}</div>
      <div className={"col-pkt col"}>{t("PTS", { keyPrefix: "table" })}</div>
    </div>
  );
};

const StandingContent = ({
  content,
  className,
  startingIndex,
  linesMarkersByRankType,
}) => {
  const { t } = useTranslation();
  const redMarkIndex = _.find(linesMarkersByRankType, {
    relegation: true,
  })?.rowIndex;
  const greenMarkIndex = _.findLast(linesMarkersByRankType, {
    relegation: false,
  })?.rowIndex;
  const rowGradientBegin = greenMarkIndex;
  const rowGradientEnd = redMarkIndex;
  return (
    <div className={"group-table"}>
      <div className={"group-content"}>
        <StandingLabel />
        {content.map((entry, index) => {
          const properIndex = index + startingIndex;
          const markRowIndex = _.findIndex(linesMarkersByRankType, {
            rowIndex: properIndex + 1,
          });
          const isMarked = markRowIndex > -1;
          //we need to simulate border top here
          const redMark = properIndex + 2 === redMarkIndex;
          return (
            <div
              key={"table-" + index}
              className={`table-row ${isMarked ? "mark-row" : ""} ${redMark ? "red-mark-row" : ""} ${rowGradientBegin > properIndex || rowGradientEnd <= properIndex + 1 ? "mark-row-gradient" : ""} ${className}`}
            >
              <div className={"col-pos col"}>{++index + startingIndex}.</div>
              <div className={"col-team col"}>
                <div className={"col-logo"}>
                  <Image
                    className="team-logo"
                    src={`/images/dynamic/soccer/team/${entry?.contestantId}.png`}
                  />
                </div>
                {t(entry?.contestantShortName || entry?.contestantName, {
                  ns: "countries",
                }) || entry?.contestantShortName}
              </div>
              <div className={"col-points col"}>{entry.matchesPlayed}</div>
              <div className={"col-points col"}>{entry.matchesWon}</div>
              <div className={"col-points col"}>{entry.matchesDrawn}</div>
              <div className={"col-points col"}>{entry.matchesLost}</div>
              <div className={"col-diff col"}>{entry.goaldifference}</div>
              <div className={"col-pkt col green-glow"}>{entry.points}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t, i18n } = useTranslation();
  const lng = i18n?.language;
  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const matchInfo = _.get(dynamic, "matchInfo");
  const standing = _.get(dynamic, "standing");
  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));
  const competitionId = matchInfo?.competition?.id;

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const teamSide = getContentById("selectedSide", fields);

  const targetTeamId = teamSide === "home" ? homeTeam?.id : awayTeam?.id;

  const findGroup = _.find(standing, (group) => {
    return (
      _.find(group?.ranking, { contestantId: homeTeam?.id }) ||
      _.find(group?.ranking, { contestantId: awayTeam?.id })
    );
  });
  let firstRanking = [];
  let secondRanking = [];
  if (findGroup?.ranking?.length) {
    firstRanking = findGroup.ranking.slice(0, 10);
    secondRanking = findGroup.ranking.slice(10);
  }

  // const linesMarkersByRankType = findRankStatusChanges(findGroup?.ranking, ['Relegation'])
  const linesMarkersByRankType = findRankStatusChanges(
    findGroup?.ranking || [],
    [],
  );
  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${
        animation ? "animation" : ""
      } side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle />*/}
        <div
          className={`wc-content wc-content-v2 wc-standing-container competition-${competitionId}`}
        >
          <ProgressBar height={"8px"} />
          <div className="wc-standing-header">
            <div className={"competition-positioner"}>
              <div className={"competition-logo-container"}>
                <Image
                  src={`/images/dynamic/soccer/competition/${competitionId}.svg`}
                />
              </div>
              <div className="second-title">
                {dynamic?.matchInfo?.week &&
                  (lng === "de" ? (
                    <span>
                      {dynamic.matchInfo.week}. {t("Matchday")}{" "}
                    </span>
                  ) : (
                    <span>
                      {t("Matchday")} {dynamic.matchInfo.week}
                    </span>
                  ))}

                {!dynamic?.matchInfo?.week &&
                  dynamic?.matchInfo?.stage?.name && (
                    <span>{t(dynamic.matchInfo.stage?.name)}</span>
                  )}
              </div>
            </div>
            <div className="main-title green-glow">
              {t("templates.Standings")}
            </div>
            <div className={"qatar-logo"}>
              <img src={assets.insight_zone_logo} />
            </div>
          </div>
          <div
            className={`wc-standing-content-container ${secondRanking?.length === 0 ? "single-side" : ""}`}
          >
            {firstRanking && (
              <StandingContent
                content={firstRanking}
                linesMarkersByRankType={linesMarkersByRankType}
                startingIndex={0}
                className={"first-group"}
              />
            )}
            {secondRanking?.length > 0 ? (
              <StandingContent
                content={secondRanking}
                linesMarkersByRankType={linesMarkersByRankType}
                startingIndex={10}
                className={"second-group"}
              />
            ) : null}
            {/*{findGroup?.ranking.map((entry, index) => {*/}
            {/*  const isMarked = _.findIndex(linesMarkersByRankType, {rowIndex: index + 1}) > -1*/}
            {/*  return <div key={'table-' + index} className={`table-row ${isMarked ? 'mark-row' : ''}`}>*/}
            {/*    <div className={'col-pos col'}>{++index}.</div>*/}
            {/*    <div className={'col-team col'}>*/}
            {/*      <div className={'col-logo'}>*/}
            {/*        /!*<img className="team-logo" src={`/images/countries/svg/${getOptaCountryCode(entry?.contestantName, entry?.contestantShortName, entry?.contestantCode)}.svg`}/>*!/*/}
            {/*        /!*<Image className="team-logo" src={`/images/countries/svg/${getOptaCountryCode(entry?.contestantName, entry?.contestantShortName, entry?.contestantCode)}.svg`}/>*!/*/}
            {/*        <Image className="team-logo" src={`/images/dynamic/soccer/team/${entry?.contestantId}.png`}/>*/}
            {/*      </div>*/}
            {/*      {t(entry?.contestantShortName || entry?.contestantName, {ns: 'countries'}) || entry?.contestantShortName}*/}
            {/*    </div>*/}
            {/*    <div className={'col-points col'}>{entry.matchesPlayed}</div>*/}
            {/*    <div className={'col-points col'}>{entry.matchesWon}</div>*/}
            {/*    <div className={'col-points col'}>{entry.matchesDrawn}</div>*/}
            {/*    <div className={'col-points col'}>{entry.matchesLost}</div>*/}
            {/*    <div className={'col-diff col'}>{entry.goaldifference}</div>*/}
            {/*    <div className={'col-pkt col'}>{entry.points}</div>*/}
            {/*  </div>*/}
            {/*})}*/}
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
