const assets = {
  _conditional: (data) => {
    const { format, apiData } = data;
    return [assets.mainbackground_1920_home, assets.mainbackground_1920_away];
  },
  mainbackground_1920_home: require("./videos/Background_02_STARTING_SIX_Home_16-9.webm"),
  mainbackground_1920_away: require("./videos/Background_02_STARTING_SIX_Away_16-9.webm"),
  mainbackground_1080_home: require("./videos/Background_02_STARTING_SIX_Home_9-16.webm"),
  mainbackground_1080_home_ribbons: require("./videos/Background_02_STARTING_SIX_Home_9-16_ribbons.webm"),
  mainbackground_1080_away: require("./videos/Background_02_STARTING_SIX_Away_9-16.webm"),
  mainbackground_1080_away_ribbons: require("./videos/Background_02_STARTING_SIX_Away_9-16_ribbons.webm"),
  mainbackground_1200_home: require("./videos/Background_02_STARTING_SIX_Home_4-5.webm"),
  mainbackground_1200_home_ribbons: require("./videos/Background_02_STARTING_SIX_Home_4-5_ribbons.webm"),
  mainbackground_1200_away: require("./videos/Background_02_STARTING_SIX_Away_4-5.webm"),
  mainbackground_1200_away_ribbons: require("./videos/Background_02_STARTING_SIX_Away_4-5_ribbons.webm"),
  sap_presents: require("../../../assets/logos/sap-presents.png"),
  penny_del: require("../../../assets/logos/penny-del-logo.png"),
  mannheimer_presents: require("../../../assets/logos/mannheimer-presents.png"),
};

export default assets;
