import { axios } from "../../src/services/axios";
import backendConfig from "../config/backend";

const API_URL = backendConfig.apiUrl + "/auth/login";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios.post(API_URL, {
    email: username,
    password,
  });
};

const refreshToken = async ({ history }) => {
  return axios({
    url: "/auth/token",
    method: "get",
    params: {},
  });

  // localStorage.removeItem("user");
  // history.push("/backend/login")
};

const logout = ({ history }) => {
  localStorage.removeItem("user");
  history.push("/backend/login");
};

const getCurrentUser = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(localStorage.getItem("user"));
  }
  return null;
};

export default {
  register,
  login,
  logout,
  getCurrentUser,
  refreshToken,
};
