import { format } from "date-fns-tz";
import { format as dateFormat } from "date-fns";
import { de, enUS } from "date-fns/locale";

export const formatNumber = (number) => {
  const parsedNumber = parseInt(number);
  return isFinite(parsedNumber) ? parsedNumber.toLocaleString("en-US") : null;
};
export const formatDate = (date, lng) => {
  const parsedDate = new Date(date);
  return dateFormat(date, lng === "de" ? "EEE, dd.MM." : "EEE, dd.MM.", {
    locale: lng === "de" ? de : enUS,
  });
};
