import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/playercompare_season";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import {
  getPlayerStats,
  getPlayerStatsSeason,
} from "../../../../shared/utils/opta/statsHandler";
import ProgressBar from "../../../../shared/components/ProgressBar";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

import {
  positionTranslations,
  positionExtension,
} from "../../../../shared/utils/opta/player";
import { useTranslation } from "react-i18next";
import { getPlayerPosition } from "../../../../shared/utils/players";
import Image from "../../../../shared/components/Image";
import { getShortPlayerName } from "../../../../shared/feeds/worldcup/playerHelper";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";
import { PlayerTriko } from "../../_partials/PlayerTriko/Index";

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const { t } = useTranslation();

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  // const headline = getContentById('headline', fields);
  const position = getContentById("playerPosition", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const matchInfo = _.get(dynamic, "matchInfo");
  const matchday = _.get(dynamic, "matchday");
  const liveData = _.get(dynamic, "liveData");
  const kits = _.get(dynamic, "kits");
  const playerCompare = _.get(dynamic, "playerCompare");

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];
  //
  // const teamHomeAlpha2 = getOptaCountryCode(homeTeam?.name, homeTeam?.shortName, homeTeam?.code);
  // const teamAwayAlpha2 = getOptaCountryCode(awayTeam?.name, awayTeam?.shortName, awayTeam?.code);

  const teamHomeAlpha2 = homeTeam?.id;
  const teamAwayAlpha2 = awayTeam?.id;

  const playerHome = _.get(playerCompare, "home");
  const playerAway = _.get(playerCompare, "away");
  const homeStats = _.get(playerHome, "stat");
  const awayStats = _.get(playerAway, "stat");

  const playerHomePositionMap = _.get(
    positionExtension,
    playerHome?.position,
    _.get(positionExtension, playerHome?.subPosition, "Midfielder"),
  );
  const playerAwayPositionMap = _.get(
    positionExtension,
    playerAway?.position,
    _.get(positionExtension, playerAway?.subPosition, "Midfielder"),
  );

  const statsMappingHome = getPlayerStatsSeason(
    _.toLower(playerHomePositionMap),
    homeStats,
  );
  const statsMappingAway = getPlayerStatsSeason(
    _.toLower(playerAwayPositionMap),
    awayStats,
  );

  const matchDetails = _.get(dynamic, "liveData.matchDetails");
  const matchStatus = _.get(matchDetails, "matchStatus");
  const scores = _.get(liveData, "matchDetails.scores.total");

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle />*/}

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.insight_zone_logo} />
          </div>

          <MatchScoreWidget
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            scores={scores}
            matchStatus={matchStatus}
            matchDetails={matchDetails}
          />

          <div className={"main-header green-glow"}>
            {t("Player Compare Season Headline", { keyPrefix: "templates" })}
          </div>

          <div className={"versus-info"}>
            <div className={"group"}>
              {matchInfo?.series?.name.replace(
                "Group",
                t("Group", { keyPrefix: "templates" }),
              ) || ""}
            </div>
            {/*<div className={'vs-team-info'}>*/}
            {/*  Spieltag {matchInfo?.week}*/}
            {/*</div>*/}
          </div>

          <div className={"teams-block"}>
            <PlayerTriko
              className={"left-player"}
              player={playerHome}
              teamId={homeTeam?.id}
              side={"home"}
              bgColor={kits?.home?.colour1}
            />

            <PlayerTriko
              className={"right-player"}
              player={playerAway}
              teamId={awayTeam?.id}
              side={"away"}
              bgColor={kits?.away?.colour1}
            />
          </div>

          <div className={"team-stats"}>
            {statsMappingHome.map((statRowHome, index) => {
              const statRowAway = statsMappingAway?.[index];
              return (
                <div key={statRowHome.type} className={"stat-row"}>
                  <div className={"stat-home"}>{statRowHome?.value || "0"}</div>
                  <div className={"stat-label"}>
                    {t(statRowHome?.type, { keyPrefix: "stats" })}
                  </div>
                  <div className={"stat-away"}>{statRowAway?.value || "0"}</div>
                </div>
              );
            })}
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
