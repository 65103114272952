import { clearTimeouts, engine as anime } from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;

  const timeline = anime.timeline();
  // $(`#${id} .headline`).css("opacity", 0);
  // $(`#${id} .table`).css("opacity", 0);

  const wiseContent = 845;

  anime({
    targets: [`#${id} .slide-1`],
    duration: 10000,
    easing: "linear",
    top: [0, -$(`#${id} .slide-1 > div`).height() + wiseContent],
    // rotateZ: [22, 22],
    opacity: [1, 1],
    delay: 1000,
  });

  anime({
    targets: [`#${id} .slide-1`, `#${id} .slide-1-head`],
    duration: 500,
    easing: "easeOutQuint",
    opacity: [1, 0],
    delay: 11000,
  });

  const timeout1 = setTimeout(() => {
    anime({
      targets: [`#${id} .slide-2`, `#${id} .slide-2-head`],
      duration: 500,
      easing: "easeOutQuint",
      opacity: [0, 1],
    });

    anime({
      targets: [`#${id} .slide-2`],
      duration: 6000,
      easing: "linear",
      top: [0, 0],
    });

    anime({
      targets: [`#${id} .slide-2`, `#${id} .slide-2-head`],
      duration: 500,
      easing: "easeOutQuint",
      opacity: [1, 0],
      delay: 7000,
    });
  }, 12000);

  const timeout2 = setTimeout(() => {
    anime({
      targets: [`#${id} .slide-3`, `#${id} .slide-3-head`],
      duration: 500,
      easing: "easeOutQuint",
      opacity: [0, 1],
    });

    anime({
      targets: [`#${id} .slide-3 .table-content`],
      duration: 12000,
      easing: "linear",
      top: [0, -$(`#${id} .slide-3 .table-content`).height() + wiseContent],
      delay: 1000,
    });
  }, 20000);

  timeouts.push(timeout1);
  timeouts.push(timeout2);

  return {
    timeline: timeline,
    timeouts: timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
