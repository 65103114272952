import { engine as anime } from "./index";
import { clearTimeouts } from "./index";
import _ from "lodash";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { refs, id } = props;
  const { player, team } = refs;

  const timeline = anime.timeline();

  timeline
    .add({
      targets: [`#${id} .home-team-block`],
      duration: 600,
      easing: "easeInOutSine",
      opacity: [0, 1],
      translateX: [-2000, 0],
    })
    .add(
      {
        targets: [`#${id} .away-team-block`],
        duration: 600,
        easing: "easeInOutSine",
        opacity: [0, 1],
        translateX: [2000, 0],
      },
      0,
    )
    .add(
      {
        targets: [`#${id} .home-player-block`],
        duration: 600,
        easing: "easeInOutSine",
        opacity: [0, 1],
        translateX: [-2000, 0],
      },
      300,
    )
    .add(
      {
        targets: [`#${id} .away-player-block`],
        duration: 600,
        easing: "easeInOutSine",
        opacity: [0, 1],
        translateX: [2000, 0],
      },
      300,
    )
    .add(
      {
        targets: [`#${id} .stats-block`],
        duration: 600,
        easing: "easeInOutSine",
        // opacity: [0, 1],
        height: [0, $(`#${id} .stats-block`).css("height")],
      },
      800,
    )
    .add(
      {
        targets: [`#${id} .player.home`],
        duration: 800,
        easing: "easeInOutSine",
        translateX: [-2000, 0],
      },
      1000,
    )
    .add(
      {
        targets: [`#${id} .player.away`],
        duration: 800,
        easing: "easeInOutSine",
        translateX: [2000, 0],
      },
      1100,
    )
    .add(
      {
        targets: [`#${id} .stats-block > .StatRow`],
        duration: 800,
        easing: "easeInOutSine",
        translateY: [2000, 0],
        delay: anime.stagger(150),
      },
      800,
    );

  //reset some values on the fly
  const elements = $(`#${id} .stat-line-fill-overflow`);
  const values = $(`#${id} .StatRow .onlyvalue`);
  elements.css("width", 0);
  $(`#${id} .stat-home-value .onlyvalue`).text(0);
  $(`#${id} .stat-away-value .onlyvalue`).text(0);

  const timeout1 = setTimeout(() => {
    $(elements).each((index, element) => {
      const maxwidth = $(element).attr("data-width");

      anime({
        targets: [element],
        duration: 800,
        easing: "easeInOutSine",
        // opacity: [0, 1],
        width: [0, `${maxwidth}%`],
        delay: anime.stagger(100),
      });

      const $valueElement = $(`#${id} .StatRow .onlyvalue`).eq(index);
      const valueTo = $valueElement.attr("data-value");

      const increment = {
        value: 0,
      };

      anime({
        targets: increment,
        duration: 800,
        easing: "easeInOutSine",
        value: [0, valueTo],
        round: 1,
        delay: anime.stagger(100),
        update: function () {
          $valueElement.text(_.trim(increment.value));
        },
      });
    });
  }, 1800);

  timeouts.push(timeout1);

  return {
    timeline: timeline,
    timeouts: timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
