import urlParse from "url-parse";
import FrontendCfg from "../../../config/frontend";

export default (url) => {
  const parsed = urlParse(url);
  const origin = parsed.origin;
  let output = url;

  if (url) {
    if (url.indexOf("://") > -1) {
      output.replace(origin, "");
    }

    output = FrontendCfg.apiUrl + url;
    return output;
  }

  return null;
};
