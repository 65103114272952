import _ from "lodash";

const assets = {
  background_1920: require("../../../assets/wc/videos/background_1920x1080_v2.webm"),
  sport_logo: require("../../../assets/wc/images/insight_zone_white.png"),
  poweredBy: require("../../../assets/wc/images/powered_by_samsung.png"),
  world_logo: require("../../../assets/wc/images/sport_world.png"),
};

export default assets;
