import * as React from "react";

export const Trikot = ({ side = "home", id = `id-${side}` }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    // preserveAspectRatio="xMidYMid meet"
    viewBox="225 200 400 400"
  >
    <linearGradient
      id={id}
      x1={424.833}
      x2={424.833}
      y1={564.65}
      y2={360}
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset="0%"
        style={{
          stopColor: `var(--triko-svg-color, #f52724)`,
          stopOpacity: 0,
        }}
      />
      <stop
        offset="100%"
        style={{
          stopColor: `var(--triko-svg-color, #f52724)`,
          stopOpacity: 1,
        }}
      />
    </linearGradient>
    <path
      d="m366.1 251 8.8-9.1c1.4-1.5 3.4-2.3 5.5-2.3h90.3c2.4 0 4.6 1.1 6 2.9l6.7 8.5H366.1zM269 359.4l57.4 16.4V366c0-3.8-.9-7.6-2.6-11l-37.5-75.4c-.4.7-.8 1.5-.9 2.3l-17.2 79.9.8-2.4zm257-4.4c-1.7 3.4-2.6 7.2-2.6 11v9.9l57.3-16.4-16.5-77.5c-.2-.8-.5-1.5-.9-2.2L526 355zm-199.6 26-58.6-16.8-3.1 14.4c-.8 3.8 1.3 7.6 5 8.9l53.9 18.7c1 .4 1.9.9 2.7 1.7l.1-26.9zm258.6-2.1-3.1-14.6-58.4 16.7.1 26.9c.8-.7 1.7-1.3 2.8-1.7l53.6-18.5c3.6-1.2 5.8-5 5-8.8zm-63.8 40.3-2.7-53.2c0-4.6 1.1-9.2 3.1-13.3l37.9-76.3s.3 0-.5-.2L493.5 256H354.8l-64.1 20.2c-.3 0-.3.2-.3.2l37.9 76.3c2 4.1 3.1 8.7 3.1 13.3l-2.7 53.2V563c0 4.3 3.4 7.7 7.7 7.7h177c4.3 0 7.7-3.4 7.7-7.7V419.2h.1z"
      style={{
        fill: `url(#id-${side})`,
      }}
    />
  </svg>
);
