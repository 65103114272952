import React, { useEffect } from "react";

import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, { animationCleanUp } from "./animation";

import assets from "./assets";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";

import "./style/style.scss";
import "./style/animation.scss";
import { PlayerTriko } from "../../_partials/PlayerTriko/Index";
import getContentById from "../../../../shared/utils/getContentById";

const getMainBackground = () => {
  return assets.background_1920;
};

const radius = 30;
const goalRadius = 50;
const width = 929;
const height = 669;
const wholeFieldHeight = height * 2;
const cutoff = height;

function getCssCoord(y, height, r) {
  const ratio = height / 100;

  return y * ratio - r * 2;
}

function getCssBottom(x, r = radius) {
  return getCssCoord(x, wholeFieldHeight, r) - cutoff;
}

function getCssRight(y, r = radius) {
  return getCssCoord(y, width, r);
}

function getSvgX(y, r) {
  return width - getCssRight(y, r) - r;
}

function getSvgY(x, r) {
  return height - getCssBottom(x, r) - r;
}

function getSvgPoint(x, y, r = radius) {
  return `${getSvgX(y, r)},${getSvgY(x, r)}`;
}

export default function Index({ animation, data, mode, format, ...props }) {
  const { t } = useTranslation();

  const templateName = data?.template_name ?? "";
  const id = `${templateName}_${data?.uuid}`;

  const {
    matchInfo = {},
    matchDetails = {},
    attackingZones = {},
    teamSide,
    goalData = [],
    kits,
  } = data?.data?.dynamic;

  const matchStatus = matchDetails?.matchStatus;
  const scores = matchDetails?.scores;

  const fields = data?.data?.fields;

  const backgroundImage = getMainBackground(format, teamSide);

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          data,
          animation,
          mode,
          id,
          format,
        });

        animationReturn.timeline.play();
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const goal = goalData?.[goalData?.length - 1];
  const goalPos = goal?.coords?.dest;
  const goalEventId = goal?.eventId;

  const subHeadline = getContentById("subHeadline", fields);

  return (
    <div
      id={id}
      className={`T_${templateName} ${animation ? "animation" : ""} side-${teamSide}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />

        {matchStatus && (
          <div className="wc-content wc-content-v2">
            <div className="wc-bg-content" />
            <ProgressBar height="8px" />
            <div className="content">
              <div className="container">
                <div className={"left-block-side"}>
                  <div className="left">
                    <div className="main-header green-glow">
                      {t("wc_goal.headline", { keyPrefix: "templates" })}
                    </div>

                    <MatchScoreWidget
                      homeTeam={homeTeam}
                      awayTeam={awayTeam}
                      scores={scores.total}
                      matchStatus={matchStatus}
                      matchDetails={matchDetails}
                    />

                    <PlayerTriko
                      className={"left-player"}
                      player={{
                        knownName: goal.player.matchName,
                        ...goal.player,
                      }}
                      teamId={goal.teamId}
                      side={teamSide}
                      bgColor={goal.kit.colour1}
                    />
                  </div>
                </div>

                <div className={"right-block-side"}>
                  <div className="right">
                    <div className="subheadline-and-logo">
                      <div className="subheadline">
                        {t("wc_goal.subheadline", { keyPrefix: "templates" })}
                      </div>
                      <div className="qatar-logo">
                        <img src={assets.insight_zone_logo} alt="" />
                      </div>
                    </div>
                    <div className="pitch-container">
                      <div
                        className="pitch"
                        style={{ backgroundImage: `url(${assets.pitch})` }}
                      >
                        {goalData.map((data) => {
                          return (
                            <div
                              className={`circle eventid-${data.eventId} ${data.typeId === 16 ? "shooter" : undefined}`}
                              id={`player-${data.eventId}`}
                              key={`${data.eventId}`}
                              style={{
                                bottom: getCssBottom(data.x),
                                right: getCssRight(data.y),
                              }}
                            >
                              {data.player.shirtNumber}
                            </div>
                          );
                        })}
                        <img
                          src={assets.ball}
                          className={`circle goal eventid-${goalEventId}`}
                          id={`goal`}
                          style={{
                            backgroundImage: `url(${assets.ball}`,
                            bottom: height - goalRadius,
                            //bottom: getCssBottom(goalPos.x, goalRadius),
                            right: getCssRight(goalPos.y, goalRadius),
                          }}
                        ></img>
                        <svg>
                          {goalData.map((data) => {
                            //typeId 16 === Goal
                            const destRadius =
                              data.typeId === 16 ? goalRadius : radius;

                            if (data.typeId === 16) {
                              return (
                                <path
                                  id={`path-${data.eventId}`}
                                  className={`path event-${data.typeId}`}
                                  key={`${data.x}-${data.y}`}
                                  d={`M${getSvgPoint(data.x, data.y)} ${getSvgX(data.coords.dest.y, destRadius)},0z`}
                                />
                              );
                            }

                            return (
                              <path
                                id={`path-${data.eventId}`}
                                className={`path event-${data.typeId}`}
                                key={`${data.x}-${data.y}`}
                                d={`M${getSvgPoint(data.x, data.y)} ${getSvgPoint(data.coords.dest.x, data.coords.dest.y, destRadius)}z`}
                              />
                            );
                          })}
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </TemplateBlock>
    </div>
  );
}
