import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/goalshots";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../language/de/countries.json";
import { useTranslation } from "react-i18next";
import {
  getTeamGoalmouth,
  getTeamStats,
} from "../../../../shared/utils/opta/statsHandler";
import Image from "../../../../shared/components/Image";
import { toIsoDateString } from "../../../../shared/utils/functions";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import ProgressBar from "../../../../shared/components/ProgressBar";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const translatePosition = ({
  leftPercent,
  topPercent,
  maxRight,
  maxLeft,
  maxHigh,
}) => {
  // Calculate the range for left and top positions
  const leftRange = maxLeft - maxRight;
  const topRange = maxHigh;

  // Scale the percentage values to fit within the restricted area
  const scaledLeft = ((leftPercent - maxRight) / leftRange) * 100;
  const scaledTop = ((maxHigh - topPercent) / topRange) * 100;

  // Mirror the scaled left position to switch sides (from right to left)
  const mirroredLeft = 100 - scaledLeft;

  // Return the scaled left and top positions as an object
  return {
    left: mirroredLeft,
    top: scaledTop,
  };
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  // const nextGame = _.get(dynamic, "nextGame");
  const matchInfo = _.get(dynamic, "matchInfo");
  const goalShots = _.get(dynamic, "goalShots");
  const matchStats = _.get(dynamic, "matchStats");

  const thisGameDate = new Date(
    toIsoDateString(matchInfo?.date, matchInfo?.time),
  );

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = _.find(matchInfo?.contestant, { position: "home" });
  const awayTeam = _.find(matchInfo?.contestant, { position: "away" });

  const teamSide = getContentById("selectedSide", fields);

  const teamAlpha2 =
    teamSide === "home"
      ? getOptaCountryCode(homeTeam?.name, homeTeam?.shortName, homeTeam?.code)
      : getOptaCountryCode(awayTeam?.name, awayTeam?.shortName, awayTeam?.code);
  const teamId = teamSide === "home" ? homeTeam?.id : awayTeam?.id;

  const teamShots = _.filter(goalShots, (o) => {
    if (o.isOwnGoal) {
      if (
        (teamSide === "away" && o.contestantId === homeTeam.id) ||
        (teamSide === "home" && o.contestantId === awayTeam.id)
      ) {
        return true;
      }
      return false;
    }

    return o.contestantId === (teamSide === "home" ? homeTeam.id : awayTeam.id);
  });

  const homeStats = _.get(matchStats, "liveData.lineUp.0.stat");
  const awayStats = _.get(matchStats, "liveData.lineUp.1.stat");
  const scores = _.get(matchStats, "liveData.matchDetails.scores.total");
  const penalties = _.get(matchStats, "liveData.matchDetails.scores.pen");
  const hasPenalty = _.has(matchStats, "liveData.matchDetails.scores.pen");
  const hasExtraTime =
    _.has(matchStats, "liveData.matchDetails.scores.et") && !hasPenalty;

  const statsMappingHome = getTeamGoalmouth(homeStats, awayStats);
  const statsMappingAway = getTeamGoalmouth(awayStats, homeStats);

  const statsBySide = teamSide === "home" ? statsMappingHome : statsMappingAway;
  const statsVersus = teamSide === "home" ? statsMappingAway : statsMappingHome;

  const matchStatus = _.toLower(
    matchStats?.liveData?.matchDetails?.matchStatus,
  );

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${
        animation ? "animation" : ""
      } side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.insight_zone_logo} />
          </div>

          <div className={"wc-headline green-glow"}>
            {t("Shots on goal", { keyPrefix: "templates" })}
          </div>

          <div className={"versus-info"}>
            <div className={"group"}>
              {matchInfo?.series?.name.replace(
                "Group",
                t("Group", { keyPrefix: "templates" }),
              ) || ""}
            </div>
            <div className={"vs-team-info"}>
              <div>
                {t(homeTeam?.shortName, { ns: "countries" })} vs{" "}
                {t(awayTeam?.shortName, { ns: "countries" })}
              </div>
              <span className={"score-separator"}>{"|"}</span>
              <div className={"score"}>
                <div className={"score-home"}>
                  {scores?.home || (matchStatus === "fixture" ? "-" : 0)}
                </div>
                <div className={"separator"}>:</div>
                <div className={"score-away"}>
                  {scores?.away || (matchStatus === "fixture" ? "-" : 0)}
                </div>
                <div className={"score-type"}>
                  {_.toLower(matchStatus) === "played"
                    ? (hasPenalty && (
                        <span className={"won-by-status"}>
                          {t("WonByPenalties")}
                        </span>
                      )) ||
                      (hasExtraTime && (
                        <span className={"won-by-status"}>
                          {t("WonOnExtraTime")}
                        </span>
                      ))
                    : null}
                </div>
              </div>
            </div>
            {/*<div className={"legend"}>*/}
            {/*  <div>*/}
            {/*    <div className={"shot color-goal"}></div>*/}
            {/*    <div>{t('Goal', {keyPrefix: 'templates'})}</div>*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <div className={"shot color-saved"}></div>*/}
            {/*    <div>{t('Saved', {keyPrefix: 'templates'})}</div>*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <div className={"shot color-post"}></div>*/}
            {/*    <div>{t('Post', {keyPrefix: 'templates'})}</div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          <div className={"team-logo-container"}>
            {/*<Image className="team-logo" src={`/images/countries/svg/${teamAlpha2}.svg`} />*/}
            <Image
              className="team-logo"
              src={`/images/dynamic/soccer/team/${teamId}.png`}
            />
            <div className={"team-name home"}>
              {teamSide === "home"
                ? t(homeTeam?.name, { ns: "countries" })
                : t(awayTeam?.name, { ns: "countries" })}
            </div>
          </div>

          <div className={"dynamic-content"}>
            <div className={"goalmouth-content"}>
              <img src={assets.goalmouth} />
              <div className={"layer-shots"}>
                {_.map(teamShots, (shot, shotIndex) => {
                  const isOwnGoal = shot?.isOwnGoal;
                  const isPenalties = shot?.periodId == 5;
                  const typeToColor = {
                    Goal: "goal",
                    "Attempt Saved": "saved",
                    Post: "post",
                    Penalty: "penalties",
                  };

                  let targetColorType = typeToColor[shot?.eventTypeLabel];

                  if (isPenalties) {
                    targetColorType = typeToColor["Penalty"];

                    return null;
                  }

                  const goalCoordsParams = {
                    maxHigh: 42.0,
                    maxRight: 44.2,
                    maxLeft: 55.8,
                  };

                  const leftPercent = shot?.goalY;
                  const topPercent = shot?.goalZ;

                  const translations = translatePosition({
                    leftPercent,
                    topPercent,
                    ...goalCoordsParams,
                  });

                  return (
                    <div
                      key={"shot-" + shot.eventId}
                      data-y={translations.top}
                      data-z={translations.left}
                      data-goalposition={shot?.goalPositionLabel}
                      className={`shot color-${targetColorType} ${
                        isOwnGoal && "own-goal"
                      }`}
                      data-event={shot?.entryTypeLabel}
                      data-player={shot?.playerName}
                      data-time={`${shot?.timeMin}:${shot?.timeSec}`}
                      style={{
                        left: `${translations.left}%`,
                        top: `${translations.top}%`,
                      }}
                    >
                      {/*{shotIndex+1}*/}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={"field-content"}>
              <img src={assets.goalmouth_field} />
              <div className={"layer-positions"}>
                {_.map(teamShots, (shot, shotIndex) => {
                  const isOwnGoal = shot?.isOwnGoal;
                  const isPenalties = shot?.periodId == 5;
                  const typeToColor = {
                    Goal: "goal",
                    "Attempt Saved": "saved",
                    Post: "post",
                    Penalty: "penalties",
                  };

                  let targetColorType = typeToColor[shot?.eventTypeLabel];

                  if (isPenalties) {
                    targetColorType = typeToColor["Penalty"];
                    return null;
                  }

                  const leftPercent = 100 - shot?.y;
                  const topPercent = 100 - shot?.x;

                  return (
                    <div
                      key={"position-" + shot.eventId}
                      data-y={topPercent}
                      data-x={leftPercent}
                      data-goalposition={shot?.goalPositionLabel}
                      className={`shot color-${targetColorType} ${
                        isOwnGoal && "own-goal"
                      }`}
                      data-event={shot?.entryTypeLabel}
                      data-player={shot?.playerName}
                      data-time={`${shot?.timeMin}:${shot?.timeSec}`}
                      style={{
                        left: `${leftPercent}%`,
                        top: `${topPercent}%`,
                      }}
                    >
                      {/*{shotIndex+1}*/}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className={"stats-block"}>
          {statsBySide.map((statRowHome, index) => {
            let penaltyValue = penalties?.[teamSide] || 0;
            let statColor =
              statRowHome.type === "calcParaden" ? "calc-paraden" : "";

            if (statRowHome.type === "goals") {
              statColor = "goals";
            }

            if (statRowHome.type !== "calcParaden") {
              return (
                <React.Fragment key={"stats1" + statRowHome.type}>
                  <div className={`stat-row color-${statColor}`}>
                    <div className={"stat-label"}>
                      {t(statRowHome?.type, { keyPrefix: "stats" })}
                    </div>
                    <div className={"stat-value"}>
                      {statRowHome?.value || "-"}
                      {/*{hasPenalty && statRowHome.type === "goals" ? <span className={"color-penalties"}>({penaltyValue})</span> : ''}*/}
                    </div>
                  </div>
                  {index < statsBySide.length - 1 && (
                    <div className={"stat-separator"}></div>
                  )}
                </React.Fragment>
              );
            } else if (statRowHome.type === "calcParaden") {
              return (
                <React.Fragment key={"stats2" + statRowHome.type}>
                  <div className={`stat-row color-${statColor}`}>
                    <div className={"stat-label"}>
                      {t(statRowHome?.type, { keyPrefix: "stats" })}
                    </div>
                    <div className={"stat-value"}>
                      {statRowHome.deps["#VERSUS#saves"] || 0}
                      <span>
                        {statRowHome?.value || "-"}
                        <span className={"sign"}>%</span>
                      </span>
                    </div>
                  </div>
                  {index < statsBySide.length - 1 && (
                    <div className={"stat-separator"}></div>
                  )}
                </React.Fragment>
              );
            }
          })}
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
