import _ from "lodash";
import { globalAssets } from "../../../assets/global/assets";

const assets = {
  ...globalAssets,
  heatmap_pitch: require("../../../assets/wc/images/pitch_heatmap_transparent.png"),
  right_arrow: require("../../../assets/svg/arrow-right.svg"),
};

export default assets;
