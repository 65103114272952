import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import getContentById from "../../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";

import { engine, fadeIn, slide } from "../../../../../animations/index";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/adler/referee";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from "./assets";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";

require("./style/style.scss");
require("./style/animation.scss");

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const { animation, data, images: Images, apiSetup, mode, format } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`)
        .get(0)
        .addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const goalsHome = _.get(dynamic, "goals.home");
  const goalsAway = _.get(dynamic, "goals.visitor");
  const eventData = _.get(dynamic, "game");
  const standingData = _.get(dynamic, "standing");
  const apiAssets = _.get(dynamic, "assets");
  const referees = _.get(dynamic, "game.referees");
  // const teamDataStyles = _.get(teamData, "styles", {});
  const playerData = getContentById("player", fields, "self");

  const teamSide = _.get(dynamic, "teamSide");

  const backgroundImage = getMainBackground(format, teamSide);
  const customBackground = getContentById("background", fields);
  // const backgroundMask = getContentById('defaultBackgroundMask', options);

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} />

        <div className={"headline"}>
          <div className={"main-line"}>SCHIEDSRICHTER</div>
          <div className={"ghost-line"}>SCHIEDSRICHTER</div>
        </div>
        <div className={"headline2"}>
          <div className={"main-line"}>LINIENRICHTER</div>
          <div className={"ghost-line"}>LINIENRICHTER</div>
        </div>

        {/*<img className={"sponsors sponsor-del"} src={assets.penny_del}/>*/}

        <div className={"teams-block"}>
          <div className={"team-logo-container home"}>
            <img
              src={parseMediaUrl(
                _.get(teamSide === "home" ? teamHome : teamAway, "url", null),
              )}
            />
          </div>
          {/*<div className={"team-vs"}>vs</div>*/}
          {/*<div className={"team-logo-container away"}>*/}
          {/*  <img src={parseMediaUrl(_.get(teamAway, 'url', null))}/>*/}
          {/*</div>*/}
        </div>

        <div className={"main-referees referees"}>
          <div className={"headReferee1"}>
            {_.get(referees, "headReferee1.name")}
          </div>
          <div className={"headReferee2"}>
            {_.get(referees, "headReferee2.name")}
          </div>
        </div>
        <div className={"line-referees referees"}>
          <div className={"lineReferee1"}>
            {_.get(referees, "lineReferee1.name")}
          </div>
          <div className={"lineReferee2"}>
            {_.get(referees, "lineReferee2.name")}
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  if (format === "1920x1080") {
    return _.get(assets, `mainbackground_1920`);
  }
};

export default Index;
