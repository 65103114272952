import React, { Component, useEffect, useState } from "react";
import "./styles/main.scss";

export const WcBgCircle = (props) => {
  const { circlePosition = "center" } = props;
  return (
    <div className={`comp-backgrounds-wcbgcircle ${circlePosition}`}>
      <div className={"wc-background"}>
        <img src={"/images/animation/wc_bgcircle/bg_gradient.jpg"} />
      </div>

      <div className={"circle-1"}>
        <img src={"/images/animation/wc_bgcircle/bg_line1.png"} />
      </div>
      <div className={"circle-2"}>
        <img src={"/images/animation/wc_bgcircle/bg_line2.png"} />
      </div>
      <div className={"circle-3"}>
        <img src={"/images/animation/wc_bgcircle/bg_line3.png"} />
      </div>
      <div className={"circle-4"}>
        <img src={"/images/animation/wc_bgcircle/bg_line4.png"} />
      </div>
    </div>
  );
};
