import anime from "animejs";

export const slide = (props) => {
  const timeline = anime.timeline();
  const { _direction, _out } = props;

  const getDirectionValues = (direction) => {
    if (direction === "left") {
      return {
        opacity: [0, 1],
        translateX: [-2000, 0],
      };
    } else if (direction === "right") {
      return {
        opacity: [0, 1],
        translateX: [2000, 0],
      };
    } else if (direction === "top") {
      return {
        opacity: [0, 1],
        translateY: [-2000, 0],
      };
    } else if (direction === "bottom") {
      return {
        opacity: [0, 1],
        translateY: [2000, 0],
      };
    }
  };

  timeline.add({
    targets: props.targets,
    duration: 1000,
    easing: "easeInOutCirc",
    delay: props.delay,
    ...getDirectionValues(_direction),
  });

  return timeline;
};

export const fadeIn = (props) => {
  const timeline = anime.timeline();
  timeline.add({
    targets: props.targets,
    opacity: [0, 1],
    duration: 500,
    easing: "easeInSine",
    delay: props.delay,
  });

  return timeline;
};

export const fadeOut = (props) => {
  const timeline = anime.timeline();
  timeline.add({
    targets: props.targets,
    opacity: [1, 0],
    duration: 500,
    easing: "easeOutSine",
    delay: props.delay,
  });

  return timeline;
};

export const clearTimeouts = (timeouts) => {
  if (timeouts && timeouts.length) {
    timeouts.forEach(clearTimeout);
  }
};

export const engine = anime;
