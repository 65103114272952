import { clearTimeouts, engine as anime } from "./index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;

  const timeline = anime.timeline();

  // anime({
  //   targets: [`#${id} .custom-background`],
  //   duration: 14000,
  //   easing: "linear",
  //   delay: 0
  // });

  return {
    timeline: timeline,
    timeouts: timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
