import _ from "lodash";
import iso from "iso-3166-1";
import backendConfig from "../../../config/backend";

const opta3ToAlpha2Fallback = {
  "Korea Republic": "kr",
  Wales: "gb-wls",
  England: "gb-eng",
  Iran: "ir",
  Island: "is",
  Georgien: "ge",
  "St. Kitts and Nevis": "kn",
  "China PR": "cn",
  Vietnam: "vn",
  Usa: "us",
  "United States": "us",
};

export default (countryName, countryCodeShortcut, optaAlpha3) => {
  let alpha2 = null;

  if (countryName) {
    alpha2 = _.lowerCase(iso.whereCountry(countryName)?.alpha2);

    if (countryCodeShortcut && !alpha2) {
      alpha2 = _.lowerCase(iso.whereCountry(countryCodeShortcut)?.alpha2);
    }
  }

  if (!alpha2 && optaAlpha3) {
    alpha2 = _.lowerCase(iso.whereAlpha3(optaAlpha3)?.alpha2);
  }

  if (!alpha2) {
    alpha2 = _.get(opta3ToAlpha2Fallback, countryName, null);
  }

  if (backendConfig.clientBodyClass === "app-opta") {
    return alpha2 || "";
  }

  return alpha2 || "de";
};
