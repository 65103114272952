import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/tournamentranking";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import { useTranslation } from "react-i18next";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";
import Image from "../../../../shared/components/Image";
import { getShortPlayerName } from "../../../../shared/feeds/worldcup/playerHelper";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import ProgressBar from "../../../../shared/components/ProgressBar";
import i18n from "i18next";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

// {
//   Goals,
//   Assists,
//   TotalGames,
//   YellowCards,
//   RedCards,
//   TotalGamesOverall,
//   GoalsOverall,
//   YellowCardsOverall,
//   RedCardsOverall,
//   SubOnOverall,
//   SubOffOverall,
//   AssistsOverall
// }
const RankingTypesToLabel = {
  Goals: "Top-Torschützen",
  Assists: "Top-Vorlagengeber",
  TotalGames: "Top-TotalGames",
  YellowCards: "Top-YellowCards",
  RedCards: "Top-RedCards",
  TotalGamesOverall: "Top-TotalGamesOverall",
  GoalsOverall: "Top-GoalsOverall",
  YellowCardsOverall: "Top-YellowCardsOverall",
  RedCardsOverall: "Top-RedCardsOverall",
  SubOnOverall: "Top-SubOnOverall",
  SubOffOverall: "Top-SubOffOverall",
  AssistsOverall: "Top-AssistsOverall",
};

const RankingTypesToColumn = {
  Goals: "Tore",
  Assists: "Vorlagen",
  TotalGames: "Spiele",
  YellowCards: "Gelbe Karten",
  RedCards: "Rote Karten",
  TotalGamesOverall: "Spiele Insgesamt",
  GoalsOverall: "Tore Insgesamt",
  YellowCardsOverall: "Gelbe Karten Insgesamt",
  RedCardsOverall: "Rote Karten Insgesamt",
  SubOnOverall: "Sub-On Overall",
  SubOffOverall: "Sub-Off Overall",
  AssistsOverall: "Vorlagen Insgesamt",
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const matchInfo = _.get(dynamic, "matchInfo");
  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const teamSide = getContentById("selectedSide", fields);
  const targetTeamId = teamSide === "home" ? homeTeam?.id : awayTeam?.id;
  const lng = i18n?.language;

  const target = getContentById("target", fields);
  const rankingPlayerType = getContentById("rankingPlayerType", fields);
  const rankingTeamType = getContentById("rankingTeamType", fields);
  const teamRankingData = _.get(dynamic, "teamRanking");
  const playerRankingData = _.get(dynamic, "playerRanking");
  const competitionId = matchInfo?.competition?.id;

  const targetRankingType =
    target === "player" ? rankingPlayerType : rankingTeamType;

  const headline = t(target + "_" + targetRankingType, {
    keyPrefix: "rankingLabels",
  });
  const labelColumns =
    target === "player"
      ? playerRankingData?.[0]?.additionalStats.map(
          (entry, index) => entry.type,
        )
      : teamRankingData?.[0]?.additionalStats.map((entry, index) => entry.type);

  console.log("dynamic.matchInfo.stage?.name", dynamic.matchInfo.stage?.name);

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle />*/}

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.insight_zone_logo} />
          </div>

          <div className={"competition-positioner"}>
            <div className={"competition-logo-container"}>
              <Image
                src={`/images/dynamic/soccer/competition/${competitionId}.svg`}
              />
            </div>
            <div className="second-title">
              {dynamic?.matchInfo?.week &&
                (lng === "de" ? (
                  <span>
                    {dynamic.matchInfo.week}. {t("Matchday")}{" "}
                  </span>
                ) : (
                  <span>
                    {t("Matchday")} {dynamic.matchInfo.week}
                  </span>
                ))}

              {!dynamic?.matchInfo?.week && dynamic?.matchInfo?.stage?.name && (
                <span>{t(dynamic.matchInfo.stage?.name)}</span>
              )}
            </div>
          </div>

          <div className={`group-table target-${target}`}>
            <div className={"main-header green-glow"}>
              {headline}
              {/*{ t(rankingType, {keyPrefix: 'templates.TopPerformers'})}*/}
            </div>
            {teamRankingData && teamRankingData?.length ? (
              <div className={"group-content"}>
                <div className={"table-head"}>
                  {/*<div className={'col-points'}>{t(rankingType + 'Col', {keyPrefix: 'templates.TopPerformers'})}</div>*/}
                  {labelColumns?.map((additonalLabel, index2) => {
                    let finalLabel = additonalLabel;
                    if (additonalLabel === "total pass") {
                      finalLabel = "PPG";
                    }

                    if (additonalLabel === "total scoring att") {
                      finalLabel = "SPG";
                    }

                    return (
                      <div className={"col-points"}>
                        {t(finalLabel, { keyPrefix: "rankingLabels" })}
                      </div>
                    );
                  })}
                </div>
                {teamRankingData.map((entry, index) => {
                  return (
                    <div key={"table-" + index} className={"table-row"}>
                      <div className={"col-pos"}>{++index}.</div>
                      <div className={"col-logo"}>
                        <Image
                          className="team-logo"
                          src={`/images/dynamic/soccer/team/${entry.id}.png`}
                        />
                      </div>
                      <div className={"col-team"}>
                        {t(entry.name, { ns: "countries" })}
                      </div>
                      {entry?.additionalStats.map((additional, index2) => {
                        let finalValue = additional.value;
                        // ppg
                        if (additional?.type === "total pass") {
                          const totalGames = _.find(entry?.calcStats, {
                            type: "total games",
                          })?.value;
                          finalValue = _.round(
                            parseFloat(additional.value / totalGames) || 0,
                            1,
                          ).toFixed(1);
                        }

                        if (additional?.type === "total scoring att") {
                          const totalGames = _.find(entry?.calcStats, {
                            type: "total games",
                          })?.value;
                          finalValue = _.round(
                            parseFloat(additional.value / totalGames) || 0,
                            1,
                          ).toFixed(1);
                        }
                        return <div className={"col-points"}>{finalValue}</div>;
                      })}
                    </div>
                  );
                })}
              </div>
            ) : null}

            {playerRankingData && playerRankingData?.length ? (
              <div className={"group-content"}>
                <div className={"table-head"}>
                  {/*<div className={'col-points'}>{t(rankingType + 'Col', {keyPrefix: 'templates.TopPerformers'})}</div>*/}
                  {labelColumns.map((additonalLabel, index2) => {
                    if (additonalLabel === "total_points") {
                      return (
                        <div className={"col-points"}>
                          {t("total points", { keyPrefix: "rankingLabels" })}
                        </div>
                      );
                    }

                    let finalLabel = additonalLabel;
                    if (additonalLabel === "total pass") {
                      finalLabel = "PPG";
                    }

                    if (additonalLabel === "total scoring att") {
                      finalLabel = "SPG";
                    }

                    if (additonalLabel === "total att assist") {
                      finalLabel = "total att assist PG";
                    }

                    return (
                      <div className={"col-points"}>
                        {t(finalLabel, { keyPrefix: "rankingLabels" })}
                      </div>
                    );
                  })}
                </div>
                {playerRankingData.map((entry, index) => {
                  return (
                    <div key={"table-" + index} className={"table-row"}>
                      <div className={"col-pos"}>{++index}.</div>
                      <div className={"col-logo"}>
                        <Image
                          className="team-logo"
                          src={`/images/dynamic/soccer/team/${entry?.teamId}.png`}
                        />
                      </div>
                      <div className={"col-team"}>
                        {t(entry.name, { ns: "countries" })}
                      </div>
                      {entry?.additionalStats.map((additional, index2) => {
                        if (additional.type === "total_points") {
                          const goals =
                            _.find(entry?.additionalStats, {
                              type: "total goals",
                            })?.value || 0;
                          const assists =
                            _.find(entry?.additionalStats, {
                              type: "total assists",
                            })?.value || 0;
                          return (
                            <div className={"col-points"}>
                              {parseInt(goals + assists) || "0"}
                            </div>
                          );
                        }

                        let finalValue = additional.value;
                        // ppg
                        if (additional?.type === "total pass") {
                          const totalGames = _.find(entry?.calcStats, {
                            type: "total games",
                          })?.value;
                          finalValue = _.round(
                            parseFloat(additional.value / totalGames) || 0,
                            1,
                          ).toFixed(1);
                        }

                        if (additional?.type === "total scoring att") {
                          const totalGames = _.find(entry?.calcStats, {
                            type: "total games",
                          })?.value;
                          finalValue = _.round(
                            parseFloat(additional.value / totalGames) || 0,
                            1,
                          ).toFixed(1);
                        }

                        if (additional?.type === "total att assist") {
                          const totalGames = _.find(entry?.calcStats, {
                            type: "total games",
                          })?.value;
                          finalValue = _.round(
                            parseFloat(additional.value / totalGames) || 0,
                            1,
                          ).toFixed(1);
                        }

                        return (
                          <div className={"col-points"}>
                            {isFinite(finalValue) ? finalValue : "-"}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
