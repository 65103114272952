import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import getContentById from "../../../../shared/utils/getContentById";
import _find from "lodash/find";
import _get from "lodash/get";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";

import { engine, fadeIn, slide } from "../../../../../animations/index";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/adler/livematchdays";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

import assets from "./assets";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";

require("./style/style.scss");
require("./style/animation.scss");

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const { animation, data, images: Images, apiSetup, mode, format } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    if (mode === "screenshot") {
      $(`#${id} video.template-background`)
        .get(0)
        .addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const goalsHome = _.get(dynamic, "goals.home");
  const goalsAway = _.get(dynamic, "goals.visitor");
  const eventData = _.get(dynamic, "game");
  const currentMatchday = _.get(dynamic, "currentMatchday");

  const currentRound = _.get(currentMatchday, "round");
  const roundOverview = _.get(dynamic, "roundOverview");
  const apiAssets = _.get(dynamic, "assets");
  // const teamDataStyles = _.get(teamData, "styles", {});
  // const playerData = getContentById('player', fields, "self");

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));
  const customBackground = getContentById("background", fields);
  // const backgroundMask = getContentById('defaultBackgroundMask', options);

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;

  const currentEvent = _.get(data, "event");

  const selectedTeamId = _.get(currentEvent, "selectedTeamId");

  const isPlayoffs = currentMatchday?.league_id === 3;

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} />

        <img className={"sponsors sponsor-sap"} src={assets.sap_presents} />

        <div className={"headline"}>
          <div className={"main-line"}>
            {isPlayoffs
              ? currentMatchday?.round
              : "Spieltag " + currentMatchday?.round}
          </div>
          <div className={"ghost-line"}>
            {isPlayoffs
              ? currentMatchday?.round
              : "Spieltag " + currentMatchday?.round}
          </div>
        </div>

        {/*<div className={"team-logo-container home"}>*/}
        {/*  <img src={parseMediaUrl(_.get(teamHome, 'url', null))}/>*/}
        {/*</div>*/}

        <div className={"live-matchdays-container"}>
          {_.map(roundOverview, (match, matchIndex) => {
            const homeId = _.get(match, "home.id").toString();
            const awayId = _.get(match, "guest.id").toString();

            const isAdlerMatch =
              selectedTeamId === homeId || selectedTeamId === awayId
                ? true
                : false;
            const matchStatus = _.get(match, "status");
            let matchStatusClass = "live";
            let statusString = "Live";

            if (matchStatus === "AFTER_MATCH") {
              matchStatusClass = "after";
              statusString = "Beendet";
            } else if (matchStatus === "BEFORE_MATCH") {
              matchStatusClass = "before";
              statusString = "";
            }

            return (
              <div
                key={`m-${match.id}`}
                className={`match ${isAdlerMatch ? "adler-match" : ""} status-${matchStatusClass}`}
              >
                {/*<div className={"match"}>*/}
                <div className={"double-border"}></div>
                <div className={"double-border-first"}></div>
                <div className={"home-name"}>
                  {_.get(apiAssets, `t_${match.home.id}.data.name`) ||
                    match?.home?.name}
                </div>
                <div className={"home-logo"}>
                  <img
                    src={parseMediaUrl(
                      _.get(apiAssets, `t_${match.home.id}.url`),
                    )}
                  />
                </div>

                <div className={"score-container"}>
                  <div className={"score-home"}>
                    {_.get(match, "results.score.final.score_home", 0)}
                  </div>
                  <div className={"score-separator"}>:</div>
                  <div className={"score-away"}>
                    {_.get(match, "results.score.final.score_guest", 0)}
                  </div>
                  <div className={"match-status"}>{statusString}</div>
                  {_.get(match, "results.score.overtime", null) && (
                    <div className={"extra-time-info"}>
                      {_.get(match, "results.shooting", false) === true
                        ? "[SO]"
                        : "[OT]"}
                    </div>
                  )}
                </div>

                <div className={"away-logo"}>
                  <img
                    src={parseMediaUrl(
                      _.get(apiAssets, `t_${match.guest.id}.url`),
                    )}
                  />
                </div>
                <div className={"away-name"}>
                  {_.get(apiAssets, `t_${match.guest.id}.data.name`) ||
                    match?.guest?.name}
                </div>
                {/*</div>*/}
              </div>
            );
          })}
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  if (format === "1920x1080") {
    return _.get(assets, `mainbackground_1920`);
  }
};

export default Index;
