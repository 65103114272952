import { clearTimeouts, engine as anime } from "../index";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format } = props;

  const timeline = anime.timeline();
  const templateDuration =
    _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0;
  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );
  $(
    `#${id} .main-title, #${id} .sub-title, #${id} .live-matchdays-container .match`,
  ).css("opacity", 0);

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 1600,
  });

  anime({
    targets: [`#${id} .main-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 600,
  });

  const timeout1 = setTimeout(() => {
    anime({
      targets: [`#${id} .live-matchdays-container .match`],
      duration: 1500,
      easing: "easeOutQuint",
      translateX: [-2000, 0],
      // rotateZ: [22, 22],
      opacity: [1, 1],
      delay: anime.stagger(50),
    });
  }, 800);

  // const timeout2 = setTimeout(() => {
  //   anime({
  //     targets: [`#${id} .live-matchdays-container .page-1`],
  //     duration: 1500,
  //     easing: "easeOutQuint",
  //     translateX: [0, 1400],
  //     // rotateZ: [22, 22],
  //     opacity: [1, 0],
  //     delay: 800
  //   })
  //
  //   anime({
  //     targets: [`#${id} .qatar-logo`],
  //     duration: 800,
  //     easing: "easeOutQuint",
  //     scale: [1, 0],
  //     opacity: [1, 1],
  //     delay: 0
  //   });
  //
  //   anime({
  //     targets: [`#${id} .live-matchdays-container .page-2`],
  //     duration: 1300,
  //     easing: "easeOutQuint",
  //     translateX: [-1400, 0],
  //     // rotateZ: [22, 22],
  //     opacity: [0, 1],
  //     delay: 1700,
  //   });
  //
  //   setTimeout(() => {
  //     anime({
  //       targets: [`#${id} .qatar-logo`],
  //       duration: 800,
  //       easing: "easeOutQuint",
  //       scale: [0, 1],
  //       opacity: [1, 1],
  //       delay: 0
  //     });
  //   }, 2500)
  // }, 15000);

  timeouts.push(timeout1);
  // timeouts.push(timeout2);

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
