import React from "react";
import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import "./index.css";
import "./language";
import App from "./App";
import configFrontend from "./config/frontend";
import {
  getWebInstrumentations,
  initializeFaro,
  LogLevel,
} from "@grafana/faro-web-sdk";
// import {TracingInstrumentation} from '@grafana/faro-web-tracing';
import {
  ConsoleInstrumentation,
  ErrorsInstrumentation,
} from "@grafana/faro-web-sdk";

console.log("INDEX - NODE_ENV", process.env.NODE_ENV);

if (
  process.env.NODE_ENV === "production" &&
  configFrontend?.client === "adler_live"
) {
  initializeFaro({
    url: "https://faro-collector-prod-eu-west-2.grafana.net/collect/521dd200c1ae9219253e4859d27bbe46",
    app: {
      name: "Adler",
      version: "1.0.0",
      environment: "production",
    },
    sessionTracking: {
      enabled: false,
    },
    instrumentations: [
      // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
      ...getWebInstrumentations({
        captureConsole: true,
        captureConsoleDisabledLevels: [
          LogLevel.DEBUG,
          LogLevel.TRACE,
          LogLevel.LOG,
        ],
      }),
      // Initialization of the tracing package.
      // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
      // new TracingInstrumentation(),
    ],
  });

  console.log("INDEX - INIT MONITORING 1 - READY");
}

if (
  process.env.NODE_ENV === "production" &&
  configFrontend?.client === "womansworldcup_live"
) {
  var faro = initializeFaro({
    url: "https://faro-collector-prod-eu-west-2.grafana.net/collect/89bb36d93afd2c1b27f222d16d2d6a0c",
    app: {
      name: "PerformStats - APP",
      version: "1.0.0",
      environment: "production",
    },
    sessionTracking: {
      enabled: false,
    },
    instrumentations: [
      // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
      ...getWebInstrumentations({
        captureConsole: true,
        captureConsoleDisabledLevels: [
          LogLevel.DEBUG,
          LogLevel.TRACE,
          LogLevel.LOG,
        ],
      }),

      // Initialization of the tracing package.
      // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
      // new TracingInstrumentation(),
    ],
  });
  console.log("INDEX - INIT MONITORING 2 - READY");
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
