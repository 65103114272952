export const getShortPlayerName = (
  entry,
  onlyFirst = false,
  onlyLast = false,
) => {
  const getNamePart = (name) => {
    const split = name.split(" ");
    if (onlyFirst) {
      if (split?.[0].indexOf(".") > -1) {
        return entry?.shortFirstName;
      }
      return split?.[0];
    }
    if (onlyLast) {
      const lastPart = split?.slice(1).join(" ").trim();
      // Handles case where name might not have a middle or last part
      if (lastPart) {
        return lastPart;
      }

      return entry?.shortLastName;
    }
    return name;
  };

  if (entry?.knownName) {
    return getNamePart(entry.knownName);
  }
  if (entry?.matchName) {
    return getNamePart(entry.matchName);
  }
  if (entry?.shortFirstName && entry?.shortLastName) {
    if (onlyFirst) {
      return entry.shortFirstName;
    }
    if (onlyLast) {
      return entry.shortLastName;
    }
    return `${entry.shortFirstName} ${entry.shortLastName}`;
  }
  return ""; // Return an empty string if no name is available
};
