const assets = {
  _conditional: (data) => {
    const { format, apiData } = data;
    return [assets.mainbackground_1920];
  },
  mainbackground_1920: require("./videos/Background_12_TOPSCORER_16-9.webm"),
  sap_presents: require("../../../assets/logos/sap-presents.png"),
  penny_del: require("../../../assets/logos/penny-del-logo.png"),
  missing_player: require("./images/missing_player.png"),
};

export default assets;
