import Image from "../../../../shared/components/Image";
import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { LiveStatusBadge } from "./LiveStatusBadge";
import _ from "lodash";

export const MatchScoreWidget = ({
  homeTeam,
  awayTeam,
  scores,
  matchStatus,
  matchDetails = {},
}) => {
  const hasPenalty = _.has(matchDetails, "scores.pen");
  const hasExtraTime = _.has(matchDetails, "scores.et") && !hasPenalty;

  const { t } = useTranslation();
  return (
    <div className={"comp-match-score-widget"}>
      <div className={"widget-container"}>
        <div className={"logo-container home"}>
          <Image
            className="team-logo"
            src={`/images/dynamic/soccer/team/${homeTeam?.id}.png`}
          />
          <div className={"team-name home"}>
            {t(homeTeam?.name, { ns: "countries" })}
          </div>
        </div>

        {matchStatus === "Played" || matchStatus === "Playing" ? (
          <div className={"score"}>
            <div className={"score-home"}>{scores?.home || 0}</div>
            <div className={"separator"}>:</div>
            <div className={"score-away"}>{scores?.away || 0}</div>
            <div className={"score-type"}>
              {matchStatus === "Played"
                ? (hasPenalty && (
                    <span className={"won-by-status"}>
                      ({t("WonByPenalties")})
                    </span>
                  )) ||
                  (hasExtraTime && (
                    <span className={"won-by-status"}>
                      ({t("WonOnExtraTime")})
                    </span>
                  ))
                : null}
            </div>
            {matchStatus === "Playing" && <LiveStatusBadge />}
          </div>
        ) : (
          <div className={"team-vs"}>
            <div className={"versus"}>{t("templates.vs")}.</div>
          </div>
        )}
        <div className={"logo-container away"}>
          <Image
            className="team-logo"
            src={`/images/dynamic/soccer/team/${awayTeam?.id}.png`}
          />
          <div className={"team-name away"}>
            {t(awayTeam?.name, { ns: "countries" })}
          </div>
        </div>
      </div>
    </div>
  );
};
