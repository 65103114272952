import React from "react";
import _get from "lodash/get";
import _filter from "lodash/filter";
import _times from "lodash/times";
import _ from "lodash";
import getContentById from "../../../shared/utils/getContentById";
import { extractStatValue } from "../../../shared/utils/functions";

// import icongoals from '../../icons/stats/goals.svg';
// import iconplayed from '../../icons/stats/minutes_played.svg';
// import iconshots from '../../icons/stats/right_foot_goals.svg';
// import iconstar from '../../icons/stats/star.svg';
// import iconassists from "../../icons/stats/passes.svg";

// import competitionNames from "FRONTENDNPM/src/frontend/src/competition-name";

const Stats = (props) => {
  const { fields, primaryColor, backgroundColor, competition } = props;

  const buildStats = (fields) => {
    const tempArr = [];

    tempArr.push({
      id: "stat1Label",
      title: getContentById("stat1Label", fields),
      value: getContentById("stat1Value", fields),
    });

    tempArr.push({
      id: "stat2Label",
      title: getContentById("stat2Label", fields),
      value: getContentById("stat2Value", fields),
    });

    tempArr.push({
      id: "stat3Label",
      title: getContentById("stat3Label", fields),
      value: getContentById("stat3Value", fields),
    });

    tempArr.push({
      id: "stat4Label",
      title: getContentById("stat4Label", fields),
      value: getContentById("stat4Value", fields),
    });

    return tempArr;
  };

  const stats = _filter(buildStats(fields), function (o) {
    return o.id !== null;
  });

  // const currentSeason = parseInt(_get(json, 'MatchInfo.CurrentSeason', 0), 10);
  // const currentSeasonInc = currentSeason+1;

  return (
    <div className={"stats-block"}>
      <div className={"live-stats"}>
        <div className={"live-stats-container"}>
          <div className={"live-stats-label"} style={{ color: primaryColor }}>
            <div className={"svg-star"}>{svgStar(primaryColor)}</div>
            Match Stats
          </div>
          {stats.map((stat, index) => renderStat(stat, index, primaryColor))}
        </div>
      </div>

      {/*<div className={'season-stats'}>*/}
      {/*    <div className={'season-stats-container'}>*/}
      {/*        <div className={"season-stats-label"} style={{color: primaryColor}}>SEASON STATS</div>*/}

      {/*        <div className={"bundesliga-logo-block"}>*/}
      {/*            <div>*/}
      {/*                <img className={"bundesliga-logo"} src={"competition"}/>*/}
      {/*                <div className={"bundesliga-title"}>{"COMPETITION"}</div>*/}
      {/*            </div>*/}

      {/*            /!*<div className={"bundesliga-season"}>{currentSeason !== 0 && (currentSeason + '/' + currentSeasonInc)}</div>*!/*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
    </div>
  );
};

const renderStat = (stat, index, primaryColor) => {
  //retrieve unit and value
  stat = extractStatValue(stat);

  if (!stat.id) {
    return null;
  }

  //retrieve unit and value
  const percentTitlePart = "(%)";
  if (stat.title.indexOf(percentTitlePart) > -1) {
    stat.unit = "%";
    // stat.title = stat.title.replace(percentTitlePart,'');
  }

  return (
    <div key={"stat" + index} className="StatRow">
      {renderCircle(stat.title, stat.value, stat.unit, primaryColor)}
    </div>
  );
};

const svgStar = (color) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"100%"}
      viewBox="0 0 240 240"
    >
      <path fill={color} d="m48,234 73-226 73,226-192-140h238z" />
    </svg>
  );
};

const renderCircle = (title, value, unit, strokeColor) => {
  //strokeDasharray="97,100" = 100%
  return (
    <React.Fragment>
      <div className="stat-circle">
        <svg className="circle-chart" xmlns="http://www.w3.org/2000/svg">
          <circle
            className="circle-chart__background"
            cx="50%"
            cy="50%"
            r="55"
            stroke="#666666"
            strokeWidth="7"
            fill="none"
          />
          <circle
            className="circle-chart__circle"
            cx="50%"
            cy="50%"
            r="55"
            stroke={strokeColor}
            strokeWidth="7"
            fill="none"
          />
        </svg>
        <div
          className="stat-live-value"
          data-value={value}
          style={{ color: strokeColor }}
        >
          {value}
        </div>
        <div className="stat-live-unit" style={{ color: strokeColor }}>
          {unit}
        </div>
      </div>
      <div className="stat-live-title">{title}</div>
    </React.Fragment>
  );
};

// const calcCircleRadius = (width, stroke) => {
//     return (width / 2) - (stroke / 2);
// };

const calcValue = (HomeValue, AwayValue) => {
  var result = 0,
    v1 = parseFloat(HomeValue),
    v2 = parseFloat(AwayValue),
    max = v1 + v2;

  if (v1 === 0 && v2 === 0) {
    return 50;
  }

  result = (v1 / max) * 100;
  return Math.round(result);
};

const getSeasonStat = (stats, statkey) => {
  const foundStat = _.find(stats, { key: statkey });
  return _.get(foundStat, "value", 0);
};

const getSeasonTitle = (stats, statkey) => {
  const foundStat = _.find(stats, { key: statkey });
  return _.get(foundStat, "title", null);
};

export default Stats;
