import React from "react";
// import checkHttp from 'FRONTENDNPM/src/frontend/src/utils/checkHttp';
// import getImageByKey from "FRONTENDNPM/src/frontend/src/utils/getImageByKey";
// import getContentById from "FRONTENDNPM/src/frontend/src/utils/getContentById";
import ImageOrVideo from "../../../shared/components/ImageOrVideo/Index";
import _get from "lodash/get";
import parseMediaUrl from "../../../shared/functions/parseMediaUrl";

const Player = React.forwardRef((props, ref) => {
  const { data, side, id, color1, styles } = props;

  // const staticContent = _.get(json, "MatchInfo.StaticContent", []);

  // const homePlayerData = _get(getContentById('predefinedHomePlayer', staticContent), '[0]', null);
  // const awayPlayerData = _get(getContentById('predefinedAwayPlayer', staticContent), '[0]', null);

  let playerImage = _get(data, "value.url", null);

  return (
    playerImage && (
      <div
        ref={ref}
        id={id}
        className={"player player-img " + side}
        style={{
          ...styles,
          borderColor: color1,
        }}
      >
        <ImageOrVideo src={parseMediaUrl(playerImage)} />
      </div>
    )
  );
});

export default Player;
