import React, { Component, useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import VigitalSolutionLogo from "../../../../assets/logo/vigital_solutions.png";
import "./loader.scss";

import Image from "../Image";

export const AlternativeLoader = (props) => {
  return (
    <div className={"comp-alternative-loader"}>
      <span className={"loader"}></span>
    </div>
  );
};

export default (props) => {
  return (
    <div className={"loader-container " + props.type}>
      <div>
        <Image src={VigitalSolutionLogo} />
        <SyncOutlined spin />
      </div>
    </div>
  );
};
