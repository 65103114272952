import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/table";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../language/de/countries.json";
import { useTranslation } from "react-i18next";
import Image from "../../../../shared/components/Image";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import ProgressBar from "../../../../shared/components/ProgressBar";
import i18n from "i18next";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const matchInfo = _.get(dynamic, "matchInfo");
  const standing = _.get(dynamic, "standing");
  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const teamSide = getContentById("selectedSide", fields);

  const targetTeamId = teamSide === "home" ? homeTeam?.id : awayTeam?.id;

  const findGroup = _.find(standing, (group) => {
    return _.find(group?.ranking, { contestantId: targetTeamId });
  });
  const competitionId = matchInfo?.competition?.id;
  const lng = i18n?.language;

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle />*/}
        <div
          className={`wc-content wc-content-v2 wc-standing-container competition-${competitionId}`}
        >
          <ProgressBar height={"8px"} />
          <div className="wc-standing-header">
            <div className={"competition-positioner"}>
              <div className={"competition-logo-container"}>
                <Image
                  src={`/images/dynamic/soccer/competition/${competitionId}.svg`}
                />
              </div>
              <div className="second-title">
                {dynamic?.matchInfo?.week &&
                  (lng === "de" ? (
                    <span>
                      {dynamic.matchInfo.week}. {t("Matchday")}{" "}
                    </span>
                  ) : (
                    <span>
                      {t("Matchday")} {dynamic.matchInfo.week}
                    </span>
                  ))}

                {!dynamic?.matchInfo?.week &&
                  dynamic?.matchInfo?.stage?.name && (
                    <span>{t(dynamic.matchInfo.stage?.name)}</span>
                  )}
              </div>
            </div>
            <div className="main-title green-glow">
              {findGroup?.groupName?.replace(
                "Group",
                t("Group", { keyPrefix: "templates" }),
              )}
            </div>
            <div className={"qatar-logo"}>
              <img src={assets.insight_zone_logo} />
            </div>
          </div>

          <div className={"group-table"}>
            {/*<div className={'group-label green-glow'}>{findGroup?.groupName?.replace('Group', t("Group", {keyPrefix: 'templates'}))}</div>*/}
            <div className={"group-content"}>
              <div className={"table-head"}>
                <div className={"col-pos col"}></div>
                <div className={"col-team col"}></div>
                <div className={"col-points col"}>
                  {t("PL", { keyPrefix: "table" })}
                </div>
                <div className={"col-points col"}>
                  {t("W", { keyPrefix: "table" })}
                </div>
                <div className={"col-points col"}>
                  {t("D", { keyPrefix: "table" })}
                </div>
                <div className={"col-points col"}>
                  {t("L", { keyPrefix: "table" })}
                </div>
                <div className={"col-diff col"}>
                  {t("GD", { keyPrefix: "table" })}
                </div>
                <div className={"col-pkt col"}>
                  {t("PTS", { keyPrefix: "table" })}
                </div>
              </div>
              {findGroup.ranking.map((entry, index) => {
                return (
                  <div key={"table-" + index} className={"table-row"}>
                    <div className={"col-pos col"}>{++index}.</div>
                    <div className={"col-team col"}>
                      <div className={"col-logo"}>
                        {/*<img className="team-logo" src={`/images/countries/svg/${getOptaCountryCode(entry?.contestantName, entry?.contestantShortName, entry?.contestantCode)}.svg`}/>*/}
                        {/*<Image className="team-logo" src={`/images/countries/svg/${getOptaCountryCode(entry?.contestantName, entry?.contestantShortName, entry?.contestantCode)}.svg`}/>*/}
                        <Image
                          className="team-logo"
                          src={`/images/dynamic/soccer/team/${entry?.contestantId}.png`}
                        />
                      </div>
                      {entry?.contestantShortName || entry?.contestantCode}
                    </div>
                    <div className={"col-points col"}>
                      {entry.matchesPlayed}
                    </div>
                    <div className={"col-points col"}>{entry.matchesWon}</div>
                    <div className={"col-points col"}>{entry.matchesDrawn}</div>
                    <div className={"col-points col"}>{entry.matchesLost}</div>
                    <div className={"col-diff col"}>{entry.goaldifference}</div>
                    <div className={"col-pkt col"}>{entry.points}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
