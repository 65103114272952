import _ from "lodash";

const baseWidth = 1920;
const baseHeight = 1080;

export const updateScale = (containerId) => {
  const container = document.getElementById(containerId);

  if (container) {
    let scaleWidth, scaleHeight;

    // Check if running inside an iframe
    if (window.self !== window.top) {
      try {
        // Attempt to access a property of the parent window
        // const testAccess = window.top.location.href;
        // console.log('window.top', window.top);

        // If no error, calculate scale based on window size
        scaleWidth = window.innerWidth / baseWidth;
        scaleHeight = window.innerHeight / baseHeight;
      } catch (e) {
        console.error(e);
        // Accessing the parent window's properties threw an error, indicating a cross-origin iframe
        // console.warn("Cross-origin access is restricted. Unable to access the parent window's properties.");

        // Fallback or default scaling, if necessary
        scaleWidth = 1; // Example fallback value
        scaleHeight = 1; // Example fallback value
      }
    } else {
      // Not in an iframe, use container size
      scaleWidth = container.offsetWidth / baseWidth;
      scaleHeight = container.offsetHeight / baseHeight;
    }

    console.log("#DEBUG updateScale window", window);
    console.log("#DEBUG updateScale scaleHeight", scaleHeight);
    console.log("#DEBUG updateScale scaleWidth", scaleWidth);

    const scale = Math.min(scaleWidth, scaleHeight);
    document.documentElement.style.setProperty("--timeline-scale", scale);
  }
};

export const debouncedUpdateScale = _.debounce(updateScale, 300);
