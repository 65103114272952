export default async ({ id }) => {
  const $videoBg = $(`#${id} video.template-background`).get(0);
  if ($videoBg) {
    $videoBg.play();
  }

  const $videoBg2 = $(
    `#${id} .background-ribbons video.template-background`,
  ).get(0);
  if ($videoBg2) {
    $videoBg2.play();
  }

  const $videoBg3 = $(`#${id} video.template-background`).get(1);
  if ($videoBg3) {
    $videoBg3.play();
  }
};
