// collision detection for two elements
export const isCollide = ($div1, $div2) => {
  var x1 = $div1.offset().left;
  var y1 = $div1.offset().top;
  var h1 = $div1.outerHeight(true);
  var w1 = $div1.outerWidth(true);
  var b1 = y1 + h1;
  var r1 = x1 + w1;
  var x2 = $div2.offset().left;
  var y2 = $div2.offset().top;
  var h2 = $div2.outerHeight(true);
  var w2 = $div2.outerWidth(true);
  var b2 = y2 + h2;
  var r2 = x2 + w2;

  if (b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2) {
    return false;
  }

  return true;
};

export const overlap = (rect1, rect2) => {
  if (
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  ) {
    return false;
  }
  return true;
};

// extract value and unit for specific stat, based on value field (compare/single stats templates)
export const extractStatValue = (stat) => {
  const regex = new RegExp("[\\d([\\.|\\,|\\:)\\d]+", "g");

  if (typeof stat.value !== "object") {
    const value = stat.value.match(regex);
    if (value) {
      stat.unit = stat.value.replace(value[0], "");
      stat.value = value[0].toString().replace(",", ".");
    }
  } else {
    const homeValue = stat.value.home.match(regex);
    const awayValue = stat.value.away.match(regex);

    if (homeValue && awayValue) {
      stat.unit = stat.value.home.replace(homeValue[0], "");
      stat.value.home = homeValue[0].toString().replace(",", ".");
      stat.value.away = awayValue[0].toString().replace(",", ".");
    }
  }

  return stat;
};

export const toIsoDateString = (dateStr, timeStr) => {
  if (!dateStr || !timeStr) {
    return "";
  }

  // Remove any 'Z' or other extraneous characters from date and time
  const cleanedDate = dateStr.replace(/[^0-9-]/g, "");
  const cleanedTime = timeStr.replace(/[^0-9:]/g, "");

  // Concatenate date and time with 'T' separator and append 'Z' for UTC timezone
  let isoDateString = `${cleanedDate}T${cleanedTime}Z`;

  if (!cleanedTime) {
    isoDateString = isoDateString.replace(`Z`, "00:00:00Z");
  }

  // Check if the resulting string is a valid date
  const parsedDate = new Date(isoDateString);
  if (isNaN(parsedDate)) {
    console.error("Invalid date format");
    return "";
  }

  return isoDateString;
};
