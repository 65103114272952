import _ from "lodash";
import { globalAssets } from "../../../assets/global/assets";

const assets = {
  ...globalAssets,
  pitch: require("../../../assets/wc/images/pitch_half_top.png"),
  ball: require("../../../assets/wc/images/ball.png"),
};

export default assets;
