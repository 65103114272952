import { forwardRef } from "react";
import "./arrow.scss";

export default function Arrow({ id, value }) {
  return (
    <div
      className={"comp-arrow-container"}
      id={id}
      style={{ height: `${value}%` }}
    >
      <div className="comp-arrow ghost-1">
        <span className="value">{value}%</span>
      </div>
      <div className="comp-arrow ghost-2"></div>
      <div className="comp-arrow ghost-3"></div>
    </div>
  );
}
