import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import Player from './Player';
import $ from "jquery";
// import AnimationLibrary from 'FRONTENDNPM/src/frontend/src/templates/AnimationLibrary';
// import {
//     themePlayerFactsAnimation
// } from '../animations';

import getContentById from "../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../_partials/Backgrounds/Index";
import TemplateBlock from "../_partials/TemplateBlock/Index";

import animationScript from "../../../../animations/teamfacts";
import ImageOrVideo from "../../../shared/components/ImageOrVideo/Index";
import { animationCleanUp } from "../../../../animations/playerstats";
import parseMediaUrl from "../../../shared/functions/parseMediaUrl";

require("./style/style.scss");
require("./style/animation.scss");

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const { animation, data, images: Images, apiSetup, mode } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const prepareAnimation = () => {
    // const $container = $('#' + id);
    //
    // $container.find('.factsList > ul > li, .factsList > ol > li, .factsList > p ').wrapInner('<div class="li-wrapper"><div class="li-wrapper-content"> </div></div>');
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      prepareAnimation();
      animationReturn = animationScript({ refs, data, animation, mode, id });
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);
  const subheadline = getContentById("subheadline", fields);
  const teamData = getContentById("team", fields, "self");
  const teamDataStyles = _.get(teamData, "styles", {});
  const photoData = getContentById("photo", fields, "self");
  const photoDataStyles = _.get(photoData, "styles", {});
  const backgroundImage = getContentById("defaultBackground", options);
  const backgroundMask = getContentById("defaultBackgroundMask", options);

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""}`}
    >
      <Backgrounds
        bg1={parseMediaUrl(backgroundImage.url)}
        bg2={parseMediaUrl(backgroundMask.url)}
        color1={color1}
        color2={color2}
      />
      <TemplateBlock>
        <div className={"content-block"}>
          <div id="header" className={"header"}>
            <div className="team-head-block">
              <div className={"team-name"}>
                {_.get(teamData, "value.data.name", null)}
              </div>
              <div
                id={teamData.id}
                ref={_.get(refs, "team")}
                className={"team-logo"}
                style={{ ...teamDataStyles }}
              >
                <ImageOrVideo src={parseMediaUrl(teamData.value.url)} />
              </div>
            </div>

            <div
              className="headline"
              style={{ backgroundColor: color1 }}
              dangerouslySetInnerHTML={{ __html: headline }}
            ></div>
          </div>

          <div className="factsListBg">
            {subheadline && (
              <div
                className="subheadline"
                style={{ color: color1 }}
                dangerouslySetInnerHTML={{ __html: subheadline }}
              ></div>
            )}
            <div
              className={"factsList"}
              style={{ color: "" }}
              dangerouslySetInnerHTML={{
                __html: getContentById("facts", fields),
              }}
            />
          </div>
        </div>

        <div
          id={_.get(photoData, "id", null)}
          className={"players"}
          style={{ ...photoDataStyles }}
        >
          <ImageOrVideo
            className="picture"
            src={parseMediaUrl(_.get(photoData, "value.url", null))}
          />
        </div>
      </TemplateBlock>
    </div>
  );
};

export default Index;
