import _ from "lodash";
import { differenceInMinutes, parseISO } from "date-fns";
import frontendConfig from "../../../../config/frontend";
import backendConfig from "../../../../config/backend";

export const getCurrentTimelineLink = (closestMatch, customNow) => {
  const match = closestMatch?.closestMatch;
  const timelineLinks = closestMatch?.matchdayTimelines;
  const matchStatus = closestMatch?.liveMatchDetails?.matchStatus || "";
  const matchLengthMin = closestMatch?.liveMatchDetails?.matchLengthMin;
  const eventMatchString = `${match?.date}${match?.time}`;

  const DateNow = customNow
    ? new Date(customNow).toISOString()
    : new Date().toISOString();
  const DateEvent = new Date(eventMatchString).toISOString();

  if (!match?.date && !match?.time) {
    return null;
  }

  const nowDateUtc = parseISO(DateNow);
  const eventDateUtc = parseISO(DateEvent);

  const minutesDiffrence = differenceInMinutes(
    parseISO(DateNow),
    parseISO(DateEvent),
  );

  //check prematch
  const beforeMatchMinutes =
    frontendConfig?.timelineLinkAutomation["PRE-MATCH"]?.beforeInMinutes;

  if (minutesDiffrence < -beforeMatchMinutes || minutesDiffrence < 2) {
    const preLinkUuid = _.find(timelineLinks, { name: "PRE-MATCH" })?.uuid;
    const preTimelineLink = `${backendConfig.frontendUrl}/timeline/${preLinkUuid}?format=1920x1080`;
    return {
      link: preTimelineLink,
      uuid: preLinkUuid,
    };
  }

  //check live
  if (_.toLower(matchStatus) === "playing") {
    const liveLinkUuid = _.find(timelineLinks, { name: "LIVE-MATCH" })?.uuid;
    const liveTimelineLink = `${backendConfig.frontendUrl}/timeline/${liveLinkUuid}?format=1920x1080`;
    return {
      link: liveTimelineLink,
      uuid: liveLinkUuid,
    };
  }

  const postLinkUuid = _.find(timelineLinks, { name: "POST-MATCH" })?.uuid;
  const postTimelineLink = `${backendConfig.frontendUrl}/timeline/${postLinkUuid}?format=1920x1080`;

  return {
    link: postTimelineLink,
    uuid: postLinkUuid,
  };
};

export const getCurrentTimelineLinkAdler = (closestMatch, customNow) => {
  const match = closestMatch?.closestMatch;
  const timelineLinks = closestMatch?.matchdayTimelines;
  const timelineStatus = closestMatch?.currentTimelineType;
  const matchStatus = closestMatch?.matchStatus;
  const eventMatchString = `${match?.date}${match?.time}`;

  const preLinkUuid = _.find(timelineLinks, { name: timelineStatus })?.uuid;
  const preTimelineLink = `${backendConfig.frontendUrl}/timeline/${preLinkUuid}?format=1920x1080`;

  return {
    link: preTimelineLink,
    uuid: preLinkUuid,
    timelineStatus,
    matchStatus,
  };
};
