import _find from "lodash/find";
import _get from "lodash/get";

export default (id, data, property) => {
  const value = property ? property : "value";
  const findRecord = _find(data, ["id", id]);
  let undefinedValue = null;

  if (property === "styles") {
    undefinedValue = "";
  }

  if (property === "self") {
    return findRecord;
  } else {
    return _get(findRecord, value, undefinedValue);
  }
};
