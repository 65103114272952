import { clearTimeouts, engine as anime } from "./index";
import _ from "lodash";

const main = (props) => {
  const timeouts = [];
  const { refs, id } = props;
  const { player, team } = refs;

  const timeline = anime.timeline();

  timeline
    .add({
      targets: [`#${id} .team-head-block, #${id} .player-head-block`],
      duration: 800,
      easing: "easeInOutSine",
      opacity: [0, 1],
      translateX: [2000, 0],
      delay: anime.stagger(150),
    })
    .add(
      {
        targets: [`#${id} .stats-block`],
        duration: 800,
        easing: "easeInOutSine",
        opacity: [0, 1],
        translateX: [2000, 0],
      },
      700,
    )
    .add(
      {
        targets: [`#${id} .stats-block .StatRow`],
        duration: 600,
        easing: "easeInOutSine",
        opacity: [0, 1],
        translateY: [100, 0],
        delay: anime.stagger(150),
      },
      1000,
    )
    .add(
      {
        targets: [`#${id} .comp-player`],
        duration: 500,
        easing: "easeInOutSine",
        opacity: [0, 1],
        translateX: [-2000, 0],
        delay: anime.stagger(200),
      },
      250,
    )
    .add({
      targets: [`#${id} .comp-player`],
      duration: 600,
      easing: "easeInOutSine",
      scale: [1, 1.25, 1],
    });

  //reset some values on the fly
  const elements = $(`#${id} .StatRow`);
  const values = $(`#${id} .StatRow .onlyvalue`);
  $(`#${id} .StatRow .stat-live-value`).text(0);

  let someDelay = setTimeout(() => {
    $(elements).each((index, element) => {
      anime({
        targets: [$(element).find(`.circle-chart__circle`).get(0)],
        duration: 800,
        easing: "easeInOutSine",
        strokeDashoffset: [anime.setDashoffset, 0],
        opacity: [1, 1],
        delay: anime.stagger(100),
      });

      const $valueElement = $(`#${id} .StatRow .stat-live-value`).eq(index);
      const valueTo = $valueElement.attr("data-value");

      const increment = {
        value: 0,
      };

      anime({
        targets: increment,
        duration: 800,
        easing: "easeInOutSine",
        value: [0, valueTo],
        round: 1,
        delay: anime.stagger(100),
        update: function () {
          $valueElement.text(_.trim(increment.value));
        },
      });
    });

    // timeline.reverse();
    // timeline.restart();
  }, 1300);

  timeouts.push(someDelay);

  // setTimeout(()=> {
  //     timeline.reverse();
  //     timeline.restart();
  // }, 3000)

  return {
    timeline: timeline,
    timeouts: timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
