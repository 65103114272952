import React from "react";
import { useTranslation } from "react-i18next";
import "./liveStatusBadge.scss";

export const LiveStatusBadge = () => {
  const { t } = useTranslation();

  return (
    <div className="comp-live-status-badge live-status">
      <div className={"animation-container"}>
        <div className={"white-bg-positioner"}>
          <div className={"white-bg"}>
            <div className="blink-me circle"></div>
            <div>{t("Live")}</div>
          </div>
        </div>
        <div className={"red-bg-positioner"}>
          <div className={"red-bg"}>
            <span className="blink-me circle"></span>
            <div>{t("Live")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
