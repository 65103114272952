import React from "react";

import "./styles.scss";
import parseMediaUrl from "../../../../shared/functions/parseMediaUrl";

const Index = React.forwardRef((props, ref) => {
  const { image, id, styles } = props;

  const className = props.className || "";

  return (
    <div
      id={id}
      ref={ref}
      className={`comp-player ${className}`}
      style={{ ...styles }}
    >
      <img className={"player-image"} src={image} />
    </div>
  );
});

export default Index;
