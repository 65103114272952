import React, { useEffect, useState } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _, { round } from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/heatmap";
import { engine as anime } from "../../../../../animations";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import * as d3 from "d3";
import Image from "../../../../shared/components/Image";

require("./style/style.scss");
require("./style/animation.scss");

import {
  positionTranslations,
  positionExtension,
} from "../../../../shared/utils/opta/player";
import { useTranslation } from "react-i18next";
import { getPlayerPosition } from "../../../../shared/utils/players";
import {
  getPlayerHeatmapStats,
  getPlayerStats,
} from "../../../../shared/utils/opta/statsHandler";
import {
  getShortPlayerName,
  getShortPlayerNamer,
} from "../../../../shared/feeds/worldcup/playerHelper";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import { Trikot } from "../../../assets/svg/Trikot";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { PlayerTriko } from "../../_partials/PlayerTriko/Index";

const renderHeatmap = (input, id, animation) => {
  const inputFake = input;

  $(`#${id} #heatmap-${id} svg`).remove();
  // IFAB regulations (105x68 meters)
  const margin = { top: 34, right: 44, bottom: 45, left: 50 },
    width = 891 - margin.left - margin.right,
    height = 577 - margin.top - margin.bottom;

  const svg = d3
    .select(`#heatmap-${id}`)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  // Add X axis
  const x = d3
    .scaleLinear()
    .domain([0, 100])
    .range([margin.left, width - margin.right]);
  svg.append("g").attr("transform", `translate(0, ${height})`);
  // .call(d3.axisBottom(x));

  // Add Y axis
  const y = d3
    .scaleLinear()
    .domain([0, 100])
    .range([height - margin.bottom, margin.top]);
  svg.append("g");
  // .call(d3.axisLeft(y));

  const color = d3
    .scaleLinear()
    .domain([0, 0.1, 0.15, 0.2, 0.3, 0.45, 0.65])
    .range(["lightgreen", "green", "yellow", "orange", "red", "darkred"]);

  // compute the density data
  const densityData = d3
    .contourDensity()
    .x(function (d) {
      return x(d.x);
    })
    .y(function (d) {
      return y(d.y);
    })
    .weight(100)
    .size([width, height])
    .bandwidth(15)
    .thresholds(15)
    .cellSize(1)(inputFake);
  // Add animation to draw the heatmap paths gradually

  const paths = svg
    .insert("g", "g")
    .selectAll("path")
    .data(densityData)
    .enter()
    .append("path")
    .attr("d", d3.geoPath())
    .attr("stroke", "transparent")
    .attr("stroke-width", 0)
    .attr("fill", function (d) {
      return color(d.value);
    });

  return {
    paths,
  };
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t } = useTranslation();

  const [heatmapReturn, setHeatmapReturn] = useState();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }

      if (heatmapReturn?.timeouts) {
        animationCleanUp(heatmapReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  // const headline = getContentById('headline', fields);
  const position = getContentById("playerPosition", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  const matchInfo = _.get(dynamic, "matchInfo");
  const matchday = _.get(dynamic, "matchday");
  const playerStats = _.get(dynamic, "stats");
  const heatmap = _.get(dynamic, "heatmap", []);
  const teamSide = getContentById("selectedSide", fields);

  const backgroundImage = getMainBackground(format, teamSide);

  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const teamHomeAlpha2 = getOptaCountryCode(
    homeTeam?.name,
    homeTeam?.shortName,
    homeTeam?.code,
  );
  const teamAwayAlpha2 = getOptaCountryCode(
    awayTeam?.name,
    awayTeam?.shortName,
    awayTeam?.code,
  );

  // const targetAlpha = teamSide === "home" ? teamHomeAlpha2 : teamAwayAlpha2;
  const targetAlpha = teamSide === "home" ? homeTeam?.id : awayTeam?.id;

  const playerHome = playerStats;

  const targetStats = ["touches", "fouls"];
  const homeStats = _.get(playerHome, "stat", []);
  const playerStatsParsed = getPlayerHeatmapStats(homeStats);
  const kits = _.get(dynamic, "kits");

  //   .filter((stat)=> {
  //   console.log('stat.type', stat.type);
  //   console.log('targetStats.includes(stat.type)', targetStats.includes(stat.type));
  //   return targetStats.includes(stat.type)
  // }).map((stat) => {
  //   return {
  //     type: stat.type,
  //     value: round(stat.value, 1),
  //     title: t(stat.type, {keyPrefix: "stats"})
  //   };
  // });

  useEffect(() => {
    if (heatmap.length) {
      const heatmapOut = renderHeatmap(heatmap, id, animation);
      setHeatmapReturn(heatmapOut);
    }
  }, [heatmap]);

  useEffect(() => {
    if (heatmapReturn && animation) {
      const { paths } = heatmapReturn;
      const stagger = 80; // Adjust this value to control the stagger effect

      const totalAnimationDuration = 1200; // Total duration of animation in milliseconds

      paths.each(function (d, i) {
        const path = d3.select(this);
        const totalLength = path.node().getTotalLength();

        path
          .attr("stroke-dasharray", totalLength + " " + totalLength)
          .attr("stroke-dashoffset", totalLength)
          .attr("opacity", "0"); // Start with opacity 0
      });

      const timeout1 = setTimeout(() => {
        paths.each(function (d, i) {
          const path = d3.select(this);
          const totalLength = path.node().getTotalLength();

          // Create an anime.js animation
          anime({
            targets: path.node(),
            strokeDashoffset: [totalLength, 0],
            opacity: [0, 1],
            // translateX: [-200, 0],
            // scale: [2, 1],
            // scale: [0, 1],
            duration: totalAnimationDuration,
            delay: i * stagger, // Use the stagger value here
            // easing: "easeOutQuint",
            easing: "easeOutSine",
          });
        });
      }, 1200);
    }
  }, [heatmapReturn, animation]);

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${
        animation ? "animation" : ""
      } side-${teamSide}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle />*/}

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.insight_zone_logo} />
          </div>

          <div className={"left-block-side"}>
            <div className={"main-headline green-glow"}>{t("Heatmap", {})}</div>

            <div className={"teams-block"}>
              <PlayerTriko
                className={"player-position"}
                player={playerHome}
                teamId={targetAlpha}
                side={teamSide}
                bgColor={kits?.[teamSide]?.colour1}
              />
            </div>
          </div>

          <div className={"right-block-side"}>
            <div className={"heatmap-block"}>
              {/*<div className={"team-home"}>*/}
              {/*  <img*/}
              {/*    src={`/images/countries/svg/${*/}
              {/*      teamSide === "home" ? teamHomeAlpha2 : teamAwayAlpha2*/}
              {/*    }.svg`}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className={"team-away"}>*/}
              {/*  <img*/}
              {/*    src={`/images/countries/svg/${*/}
              {/*      teamSide === "home" ? teamAwayAlpha2 : teamHomeAlpha2*/}
              {/*    }.svg`}*/}
              {/*  />*/}
              {/*</div>*/}
              <div className={"pitch-container"}>
                <div id={`heatmap-${id}`} className={`player-heatmap`}></div>
                <img className={"heatmap-pitch"} src={assets.heatmap_pitch} />
                <div className={"arrows-right"}>
                  <img src={assets.right_arrow} />
                  <img src={assets.right_arrow} />
                  <img src={assets.right_arrow} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"stats-block"}>
          {playerStatsParsed.map((statRowHome, index) => {
            return (
              <React.Fragment key={"stats1" + statRowHome.type}>
                <div className={`stat-row`}>
                  <div className={"stat-label"}>
                    {t(statRowHome?.type, { keyPrefix: "stats" })}
                  </div>
                  <div className={"stat-value"}>
                    {statRowHome?.value || "0"}
                  </div>
                </div>
                {index < playerStatsParsed.length - 1 && (
                  <div className={"stat-separator"}></div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
