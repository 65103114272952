import _ from "lodash";
import { globalAssets } from "../../../assets/global/assets";

const assets = {
  ...globalAssets,
  pitch: require("../../../assets/wc/images/pitch.png"),
  trikot: require("../../../assets/wc/images/trikot.png"),
};

export default assets;
