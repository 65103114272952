import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import parseMediaUrl from "../../functions/parseMediaUrl";

const isVideoOrImage = (src) => {
  if (!src) {
    return "image";
  }

  if (
    src.indexOf(".mp4") > -1 ||
    src.indexOf(".webm") > -1 ||
    src.indexOf(".ogv") > -1
  ) {
    return "video";
  }

  return "image";
};

const ImageOrVideo = (props) => {
  const { id, src, poster, className, loop, searchParams, animation } = props;

  const muted = searchParams?.stream === "audio" && animation ? false : true;

  const autoPlay = animation ? true : false;

  if (isVideoOrImage(src) === "video") {
    return (
      <video
        key={src}
        id={id}
        className={className}
        controls={false}
        autoPlay={true}
        muted={muted}
        poster={poster}
        loop={loop || false}
      >
        <source src={src} />
      </video>
    );
  }

  return <img key={src} id={id} className={className} src={src} />;
};

export default ImageOrVideo;
