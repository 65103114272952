import _ from "lodash";

const assets = {
  _conditional: (data) => {
    const { format, apiData } = data;
    const dynamic = _.get(apiData, "data.dynamic");
    const side = _.get(dynamic, "teamSide");

    let mainVideoBg = null;

    // if (format === "1920x1080") {
    //   mainVideoBg = `mainbackground_1920_${side}`;
    // }

    if (format === "1200x1500") {
      mainVideoBg = `mainbackground_1200_${side}`;
    }

    if (format === "1080x1920") {
      mainVideoBg = `mainbackground_1080_${side}`;
    }

    return [assets[mainVideoBg], assets.sap_presents, assets.penny_del];
  },
  mainbackground_1200_home: require("./videos/Background_06_GAMEDAY_Home_4-5.webm"),
  mainbackground_1200_away: require("./videos/Background_06_GAMEDAY_Away_4-5.webm"),
  mainbackground_1080_home: require("./videos/Background_06_GAMEDAY_Home_9-16.webm"),
  mainbackground_1080_away: require("./videos/Background_06_GAMEDAY_Away_9-16.webm"),
  sap_presents: require("../../../assets/logos/sap_white.png"),
  duravit_presents: require("../../../assets/logos/duravit-presents.png"),
  penny_del: require("../../../assets/logos/penny-del-logo.png"),
};

export default assets;
