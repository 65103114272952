import React from "react";
import extToType from "../../../../shared/functions/extToType";

import "./styles.scss";

const Index = (props) => {
  const { bg1, bg2, color2, autoPlay = false, loop = false } = props;

  if (extToType(bg1) === "image") {
    return (
      <div key={bg1 + bg2} className={"comp-backgrounds"}>
        <div
          className={"template-background"}
          style={{
            backgroundImage: bg2 ? `url(${bg1}), url(${bg2})` : `url(${bg1})`,
            backgroundColor: color2,
          }}
        ></div>
      </div>
    );
  } else if (extToType(bg1) === "video") {
    return (
      <div key={bg1} className={"comp-backgrounds"}>
        <video
          className={"template-background"}
          controls={false}
          autoPlay={autoPlay}
          muted={true}
          loop={loop}
        >
          <source src={bg1} />
        </video>
      </div>
    );
  } else if (extToType(bg2) === "video") {
    return (
      <div key={bg2} className={"comp-backgrounds"}>
        <video
          className={"template-background"}
          controls={false}
          autoPlay={autoPlay}
          muted={true}
          loop={loop}
        >
          <source src={bg2} />
        </video>
      </div>
    );
  }
  return null;
};

export default Index;
