import { useRouteMatch } from "react-router-dom";
import { RoutesRender } from "../routes/Router";
import { FRONTEND_ROUTES } from "../routes/frontend";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { getApiSetup } from "../services/backend/api.service";
import { useGlobalFrontendStore } from "../stores/globalFrontendStore";
import BackendConfig from "../config/backend/index";
import ErrorBoundary from "../components/shared/components/ErrorBoundary/Index";
import { AlternativeLoader } from "../components/shared/components/Loader";

// const Home = () => <p>Home</p>;
// const Dashboard = () => <p>Dashboard</p>;
// const Login = () => <p>Login</p>;
// const Logout = () => <p>logout</p>;
// const Profile = () => <p>profile</p>;

const NotFound = (props) => {
  return <h1>Not Found ({props.match.url})</h1>;
};

require("./frontend/fonts.scss");

if (BackendConfig.clientBodyClass === "app-opta") {
  require("./frontend/fonts_rubik.scss");
} else {
  require("./frontend/fonts.scss");
}

export default function AppLayout(props) {
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const { url, path } = useRouteMatch();

  require("./frontend/main.scss");

  const setApiSetup = useGlobalFrontendStore((state) => state.setApiSetup);

  useEffect(() => {
    setIsLoading(true); // Start loading
    getApiSetup()
      .then((apiSetup) => {
        const apiData = _.get(apiSetup, "data.data", null);
        setApiSetup(apiData);
        setIsLoading(false); // Finish loading
      })
      .catch((err) => {
        console.error("apiSetup err", err);
        setIsLoading(false); // Finish loading, even on error
      });
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(90deg, #004d5c 0%, #00112c 100%)",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <AlternativeLoader />
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <RoutesRender path={path} routes={FRONTEND_ROUTES} type={"frontend"} />
    </ErrorBoundary>
  );
}
