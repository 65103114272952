import React, { useEffect, useRef, useState } from "react";
import Config from "../../../../config/frontend";

export default (props) => {
  const { showStart, showEnd } = props;
  const start = useRef();
  const end = useRef();
  const imagesLoaded = props.imagesLoaded;
  const [endProduction, setEndProduction] = useState(false);

  useEffect(() => {
    if (showStart) {
      end.current.style.display = "none";
      start.current.style.display = "block";
    } else {
      start.current.style.display = "none";
    }

    if (showEnd) {
      start.current.style.display = "none";
      end.current.style.display = "block";
    } else {
      end.current.style.display = "none";
    }
  }, [showStart, showEnd]);

  return (
    <React.Fragment>
      <div
        ref={end}
        className={"video-mask-green"}
        style={{ backgroundColor: Config.videoEndColor }}
      ></div>
      <div
        ref={start}
        className={"video-mask-blue"}
        style={{ backgroundColor: Config.videoBeginColor }}
      ></div>
    </React.Fragment>
  );
};
